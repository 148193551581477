import React from "react";
import "react-phone-input-2/lib/style.css";
import stat from "../assets/images/registerBox.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faLock,
  faDollarSign,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

const BoxInfo = () => {
  return (
    <div className="container-fluid py-5 my-5">
      <div className="row py-1 my-5">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="box-info-label-area">
            <ul className="box-info-label">
              <li className="li-1">
                <FontAwesomeIcon icon={faCheckCircle} />
                <span>Kaliteli</span>
              </li>
              <li className="li-2">
                <FontAwesomeIcon icon={faLock} />
                <span>Güvenli</span>
              </li>
              <li className="li-3">
                <FontAwesomeIcon icon={faDollarSign} />
                <span>Hesaplı</span>
              </li>
              <li className="li-4">
                <FontAwesomeIcon icon={faThumbsUp} />
                <span>Uygun</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <img src={stat} alt="Registration" className="w-100 stat" />
        </div>
      </div>
    </div>
  );
};

export default BoxInfo;
