import { useState, useEffect } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Bitrix24Form } from "react-bitrix24-form";
import PriceListModal from "../components/PriceListModal";

const navigation = [
  { name: "Outlet Ürünler", href: "/outlet" },
  { name: "Bayimiz Olur musun?", href: "#bayi-ol" },
  { name: "Kalite Standartları", href: "#standartlar" },
  { name: "İletişim", href: "#iletisim" },
];

const HeaderHome = () => {
  const openPriceModal = () => setShowPriceModal(true);
  const closePriceModal = () => setShowPriceModal(false);

  const [showPriceModal, setShowPriceModal] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const loadScript = () => {
    if (!isScriptLoaded) {
      const script = document.createElement("script");
      script.src =
        "https://gama.deltaservis.com.tr/upload/crm/form/loader_13_s339hw.js";
      script.async = true;
      script.onload = () => {
        // console.log("Script yüklendi ve çalışmaya başladı!");
        setIsModalOpen(true);
      };
      document.head.appendChild(script);
      setIsScriptLoaded(true);
    } else {
      setIsModalOpen(true);
    }
  };
  return (
    <div className="bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white">
      <header
        className={`fixed top-0 inset-x-0 z-50 w-full transition-all duration-300 ${
          scrolled ? "bg-slate-950" : "bg-opacity-70"
        } shadow-xl border-b border-white/10`}
      >
        <nav
          aria-label="Global"
          className="flex items-center justify-between p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Delta Servis</span>
              <img alt="Logo" src="/logo.png" className="h-8 w-auto" />
            </a>
            <div className="pl-5">
              <Bitrix24Form
                src="https://gama.deltaservis.com.tr/upload/crm/form/loader_13_s339hw.js"
                data="click/13/s339hw"
              />
              <button
                onClick={loadScript}
                className="lg:hidden rounded-lg bg-red-600 px-1 py-2 text-base font-semibold text-white shadow-md transition-all duration-300 hover:scale-105 hover:bg-red-700 hover:shadow-lg"
              >
                Şimdi Katıl
              </button>
            </div>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="rounded-md bg-gray-800 p-2.5 text-white transition-transform duration-300 hover:scale-105 hover:bg-gray-700 hover:shadow-lg"
            >
              <span className="sr-only">Menü</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="hidden lg:flex lg:gap-x-1">
            {navigation.map((item, index) => (
              <div key={item.name} className="flex items-center">
                <a
                  href={item.href}
                  className="text-lg rounded-md px-3 py-2 font-bold transition-all duration-300 hover:scale-125 hover:text-white"
                >
                  {item.name}
                </a>
                {index !== navigation.length - 1 && (
                  <span className="h-5 w-px bg-gray-400 mx-4"></span>
                )}
              </div>
            ))}
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-4">
            <Bitrix24Form
              src="https://gama.deltaservis.com.tr/upload/crm/form/loader_13_s339hw.js"
              data="click/13/s339hw"
            />
            <button
              onClick={loadScript}
              className="rounded-lg bg-blue-900 px-4 py-2 text-sm font-semibold shadow-lg hover:bg-blue-700"
            >
              Hemen Başvur
            </button>
            <button
              onClick={openPriceModal}
              className="rounded-lg bg-red-600 px-4 py-2 text-sm font-semibold shadow-lg hover:bg-red-700"
            >
              Fiyat Listesi İndir
            </button>
          </div>
        </nav>

        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="animate-fadeIn fixed inset-0 z-50 bg-black/50 opacity-0" />
          <DialogPanel className="animate-slideInRight fixed inset-y-0 right-0 z-50 w-full max-w-sm overflow-y-auto bg-gray-800 px-6 py-6 sm:ring-1 sm:ring-gray-700">
            <div className="flex items-center justify-between">
              <a href="#/" className="-m-1.5 p-1.5">
                <img alt="Logo" src="/logo.png" className="h-8 w-auto" />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="rounded-md bg-gray-700 p-2.5 text-white transition-transform duration-300 hover:rotate-90 hover:bg-gray-600"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-700">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-lg px-3 py-2 text-white font-semibold transition-transform duration-300 hover:scale-105 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                {/* <div className="py-6">
                  <button
                    href=""
                    className="block rounded-lg bg-red-600 px-3 py-2 text-base font-semibold text-white shadow-md transition-all duration-300 hover:scale-105 hover:bg-red-700 hover:shadow-lg"
                  >
                    Şimdi Katıl
                  </button>
                </div> */}
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
      <PriceListModal show={showPriceModal} onClose={closePriceModal} />
    </div>
  );
};
export default HeaderHome;
