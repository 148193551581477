import { useState } from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import PriceListModal from "./PriceListModal";

const Footer = () => {
  const openPriceModal = () => setShowPriceModal(true);
  const closePriceModal = () => setShowPriceModal(false);

  const [showPriceModal, setShowPriceModal] = useState(false);

  return (
    <div>
      <h1 className="text-center text-4xl font-extrabold text-white pb-5  pt-4 rounded-lg shadow-lg">
        İletişim
      </h1>

      <div className=" pb-0">
        <iframe
          title="googleMap"
          className="mb-3 maps"
          width={"100%"}
          height={"400px"}
          src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.3280248479387!2d28.862069076545847!3d40.99619302016806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9ea79191589%3A0x1b9a6b7f14ff7ec3!2sDelta%20Servis!5e0!3m2!1str!2str!4v1720169225846!5m2!1str!2str"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <footer aria-labelledby="footer-heading" className="bg-black pt-4 m-4">
        <div className="mx-auto max-w-7xl pb-8 ">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <img alt="Logo" src="/logo.png" className="h-8 w-auto" />
              <p className="text-sm leading-6 text-gray-300 space-y-3">
                <div className="flex items-center space-x-3">
                  <FaMapMarkerAlt className="h-5 w-5 text-gray-500 hover:text-gray-400" />
                  <span>
                    Bahçelievler Mah. Mehmetçik Sk. Kadir Has No: 1 /1 <br /> İç
                    Kapı No: 123 Bahçelievler/ İstanbul
                  </span>
                </div>
                <div className="flex items-center space-x-3">
                  <FaPhoneAlt className="h-5 w-5 text-gray-500 hover:text-gray-400" />
                  <a href="tel:0850 532 0 500">0850 532 0 500</a>
                </div>
                <div className="flex items-center space-x-3">
                  <FaEnvelope className="h-5 w-5 text-gray-500 hover:text-gray-400" />
                  <a href="mailto:info@deltaservis.com.tr">
                    info@deltaservis.com.tr
                  </a>
                </div>
              </p>
              <div className="flex space-x-6">
                <a
                  href="https://www.facebook.com/deltaservis/?locale=tr_TR"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-500 hover:text-gray-400"
                >
                  <span className="sr-only">Facebook</span>
                  {/* Facebook icon */}
                  <FaFacebook aria-hidden="true" className="h-6 w-6" />
                </a>
                <a
                  href="https://www.instagram.com/deltaservisofficial/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-500 hover:text-gray-400"
                >
                  <span className="sr-only">Instagram</span>
                  {/* Instagram icon */}
                  <FaInstagram aria-hidden="true" className="h-6 w-6" />
                </a>
                <a
                  href="https://tr.linkedin.com/company/deltaservis"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-500 hover:text-gray-400"
                >
                  <span className="sr-only">LinkedIn</span>
                  {/* LinkedIn icon */}
                  <FaLinkedin aria-hidden="true" className="h-6 w-6" />
                </a>
              </div>
            </div>
            <div className="mt-16 grid lg:grid-cols-3 gap-8 xl:col-span-2 xl:mt-0 grid-cols-2">
              <div className="md:grid md:grid-cols-1 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    İletişim
                  </h3>
                  <ul className="mt-6 space-y-4">
                    <li>
                      <a
                        href="/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        İletişim
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Merak Edilenler
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Hakkımızda
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Hemen Başvur
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-1 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Daha Fazla
                  </h3>
                  <ul className="mt-6 space-y-4">
                    <li>
                      <a
                        onClick={openPriceModal}
                        href="#/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Fiyat Listesi
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://avantajlar.deltaservis.com.tr/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Avantajlar
                      </a>
                    </li>
                    <li>
                      <a
                        href="#/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Online Alışveriş
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://buyback.deltaservis.com.tr/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Delta Buyback
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-8">
                {/* İlk 4 Marka */}
                <div className="space-y-4">
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Markalar
                  </h3>
                  <ul className="mt-6 space-y-4">
                    <li>
                      <a
                        href="#anasayfa"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Apple
                      </a>
                    </li>
                    <li>
                      <a
                        href="#/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Samsung
                      </a>
                    </li>
                    <li>
                      <a
                        href="#/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Huawei
                      </a>
                    </li>
                    <li>
                      <a
                        href="#/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Xiaomi
                      </a>
                    </li>
                  </ul>
                </div>

                {/* Diğer Markalar */}
                <div className="space-y-4">
                <ul className="mt-10 space-y-4">

                    <li>
                      <a
                        href="#/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Oppo
                      </a>
                    </li>
                    <li>
                      <a
                        href="#/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Realme
                      </a>
                    </li>
                    <li>
                      <a
                        href="#/"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Tecno
                      </a>
                    </li>
                    <li>
                      <a
                        href="#boomerang"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        Poco
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
            <p className="text-xs leading-5 text-gray-400">
              &copy; Telif Hakkı Delta Yenileme Merkezi ve Bilişim Teknolojileri
              A.Ş. 2024 | Tüm Hakları Saklıdır.
            </p>
          </div>
        </div>
        <PriceListModal show={showPriceModal} onClose={closePriceModal} />
      </footer>
    </div>
  );
};

export default Footer;
