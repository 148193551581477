import React, { useState, useEffect } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { cities } from "../assets/json/city";
import { districts as allDistricts } from "../assets/json/district";
import { Link } from "react-scroll";
import { Bitrix24Form } from "react-bitrix24-form";

const Register = () => {
  const [formAreaClass, setFormAreaClass] = useState(
    "row form-area h-0 overflow-hidden"
  );

  const handleClick = () => {
    setFormAreaClass("row form-area mt-5 overflow-hidden pt-3");
  };

  const [formData, setFormData] = useState({
    apikey: "5745321453468",
    name: "",
    surname: "",
    note: "",
    phone: "",
    email: "",
    city: "",
    district: "",
  });

  const [districts, setDistricts] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    const selectedCityId = formData.city;
    const filteredDistricts = allDistricts.filter(
      (district) => district.city_id === Number(selectedCityId)
    );
    setDistricts(filteredDistricts);
  }, [formData.city]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams({
      ...formData,
      city: formData.city,
      district: formData.district,
    });

    try {
      const response = await axios.post(
        "https://omega.deltaservis.com.tr/stockList/contacts/",
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setResponseMessage(response.data.message);
      setFormData({
        apikey: "5745321453468",
        name: "",
        surname: "",
        phone: "",
        email: "",
        note: "",
        city: "",
        district: "",
      });
    } catch (error) {
      setResponseMessage("Kayıt başarısız oldu. Lütfen tekrar deneyin.");
    }
  };

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const loadScript = () => {
    if (!isScriptLoaded) {
      const script = document.createElement("script");
      script.src =
        "https://gama.deltaservis.com.tr/upload/crm/form/loader_13_s339hw.js";
      script.async = true;
      script.onload = () => {
        // console.log("Script yüklendi ve çalışmaya başladı!");
        setIsModalOpen(true);
      };
      document.head.appendChild(script);
      setIsScriptLoaded(true);
    } else {
      setIsModalOpen(true);
    }
  };
  return (
    <>
      <div className="container-fluid py-5 mt-5 mb-5 parallax" id="bayi-ol">
        <div className="row">
          <h2 className="col-12 dealer-title pt-5 mt-5">Bayimiz Olur Musun?</h2>
          <div className="col-12 dealer-text contact px-5 border-0">
            Delta İş Ortağım programına katılarak, işinize özel avantajlarla
            kazancınızı artırın ve ayrıcalıklı iş modelleriyle fark
            yaratın.Delta Servis’in güçlü iş ortaklıkları sayesinde
            rakiplerinizin bir adım önünde olma fırsatını yakalayın. Siz de
            kazançlı bir yolculuk için Delta ailesinde yerinizi alın!
          </div>
          <div className="col-12 text-center">
            <Bitrix24Form
              src="https://gama.deltaservis.com.tr/upload/crm/form/loader_13_s339hw.js"
              data="click/13/s339hw"
            />
            <button onClick={loadScript}>
              <h2 onClick={handleClick} className="dealer-button">
                Bayi Ol
              </h2>
            </button>
          </div>
        </div>

        {/* <div className="container">
          <div id="formArea" className={formAreaClass}>
            <div className="col-12">
              <h4 className="text-light">Bayi Başvuru Formu</h4>
              <form className="registerForm" onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-sm-4 col-12">
                    <label>
                      Ad <span className="required">*</span>
                    </label>
                    <input
                      placeholder="Adınız"
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group col-sm-4 col-12">
                    <label>
                      Soyad <span className="required">*</span>
                    </label>
                    <input
                      placeholder="Soyad"
                      type="text"
                      className="form-control"
                      name="surname"
                      value={formData.surname}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group col-sm-4 col-12">
                    <label>
                      Telefon <span className="required">*</span>
                    </label>
                    <PhoneInput
                      country={"tr"}
                      name="phone"
                      value={formData.phone}
                      onChange={handlePhoneChange}
                      inputStyle={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-sm-4 col-12">
                    <label>
                      Email <span className="required">*</span>
                    </label>
                    <input
                      placeholder="E-Posta Adresiniz"
                      type="email"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group col-sm-4 col-12">
                    <label>
                      İl <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                    >
                      <option value="">İl seçiniz</option>
                      {cities.map((city) => (
                        <option key={city.city_id} value={city.city_id}>
                          {city.city_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-sm-4 col-12">
                    <label>
                      İlçe <span className="required">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="district"
                      value={formData.district}
                      onChange={handleChange}
                      required
                    >
                      <option value="">İlçe seçiniz</option>
                      {districts.map((district) => (
                        <option key={district.dist_id} value={district.dist_id}>
                          {district.district_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-sm-8 col-12">
                    <label>Not</label>
                    <input
                      placeholder="#Notunuz"
                      type="text"
                      className="form-control"
                      name="note"
                      value={formData.note}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-4 d-flex align-items-end">
                    <button type="submit" className="btn btn-secondary w-100">
                      Gönder
                    </button>
                  </div>
                </div>
              </form>
              {responseMessage && (
                <div className="response alert alert-info mt-3">
                  {responseMessage}
                </div>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Register;
