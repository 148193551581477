import { useEffect } from "react";
import "./SlidingTextVideo/SlidingTextVideo.css";
import WordRotate from "./WordRotate";

const HomeSlidingTextVideoComponent = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => {}, 500);
    }, 5000); // Change slogan every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="outer-box ">
      <div className="sliding-container mt-0">
        <div className="left-section">
          <div className="bg-gradient-to-r from-gray-300 to-gray-500 bg-clip-text text-4xl font-bold text-transparent">
            Delta Servis olarak iş ortaklarımıza
            <br />{" "}
            <WordRotate
              words={["Sürdürülebilir", "Kazançlı", "Kaliteli", "Güvenilir"]}
              delay={3000}
              animDelay={3000}
            />{" "}
            çözümler sunuyoruz.
          </div>
        </div>
        <div className="right-section">
          <video width="100%" height="100%" controls autoPlay loop muted>
            <source
              src="https://avantajlar.deltaservis.com.tr/avantagesvideo.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  );
};

export default HomeSlidingTextVideoComponent;
