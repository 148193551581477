import { Link } from "react-scroll";

export default function HeroSection() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900">
      <svg
        aria-hidden="true"
        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      >
        <defs>
          <pattern
            x="50%"
            y={-1}
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
          width="100%"
          height="100%"
          strokeWidth={0}
        />
      </svg>
      <div
        aria-hidden="true"
        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
          className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
        />
      </div>

      <div className="mx-auto max-w-7xl px-6 pb-24 pt-20 sm:pb-32 lg:flex lg:px-8 ">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8 flex flex-col items-center">
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl text-center">
            YENİLENMİŞ ÜRÜNLER
          </h1>
          <p className="mt-6 text-xl leading-8 text-gray-300 text-center">
            Delta Servis güvencesiyle yenilenmiş cep telefonlarına sahip olun!
            Avantajlı fiyatlar ve 12 ay taksit imkanıyla kazancınızı artırın.
            Hemen inceleyin, fırsatları kaçırmayın!
          </p>
          <div className="mt-10 flex items-center gap-x-6 justify-center">
            <Link
              to="urunler"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="rounded-md bg-red-500 px-3.5 py-2.5 text-sm cursor-pointer font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400 "
            >
              Hemen İnceleyin
            </Link>
          </div>
        </div>
        <div className="mx-4  items-center lg:flex hidden">
          <div className="h-32 w-px bg-gray-500 mx-6"></div>
        </div>
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8 flex flex-col items-center">
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl text-center">
            OUTLET <br /> ÜRÜNLER
          </h1>
          <p className="mt-6 text-xl leading-8 text-gray-300 text-center">
            Fiyat ve performansın buluşma noktası! Outlet ürünlerimizle yüksek
            kaliteye uygun fiyatlarla sahip olun. Her ürünün özel videosunu
            izleyerek satın almadan önce tüm detayları keşfedin!
          </p>
          <div className="mt-10 flex items-center gap-x-6 justify-center">
            <a
              href="/outlet"
              className="rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400"
            >
              Hemen İnceleyin
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
