export const districts = [
  {
    dist_id: 1,
    city_id: 1,
    district_name: "Seyhan",
    status: 1,
  },
  {
    dist_id: 2,
    city_id: 1,
    district_name: "Yüregir",
    status: 1,
  },
  {
    dist_id: 3,
    city_id: 1,
    district_name: "Sarıçam",
    status: 1,
  },
  {
    dist_id: 4,
    city_id: 1,
    district_name: "Çukurova",
    status: 1,
  },
  {
    dist_id: 5,
    city_id: 1,
    district_name: "Aladag(Karsantı)",
    status: 1,
  },
  {
    dist_id: 6,
    city_id: 1,
    district_name: "Ceyhan",
    status: 1,
  },
  {
    dist_id: 7,
    city_id: 1,
    district_name: "Feke",
    status: 1,
  },
  {
    dist_id: 8,
    city_id: 1,
    district_name: "Imamoglu",
    status: 1,
  },
  {
    dist_id: 9,
    city_id: 1,
    district_name: "Karaisalı",
    status: 1,
  },
  {
    dist_id: 10,
    city_id: 1,
    district_name: "Karatas",
    status: 1,
  },
  {
    dist_id: 11,
    city_id: 1,
    district_name: "Kozan",
    status: 1,
  },
  {
    dist_id: 12,
    city_id: 1,
    district_name: "Pozantı",
    status: 1,
  },
  {
    dist_id: 13,
    city_id: 1,
    district_name: "Saimbeyli",
    status: 1,
  },
  {
    dist_id: 14,
    city_id: 1,
    district_name: "Tufanbeyli",
    status: 1,
  },
  {
    dist_id: 15,
    city_id: 1,
    district_name: "Yumurtalık",
    status: 1,
  },
  {
    dist_id: 16,
    city_id: 2,
    district_name: "Adıyaman",
    status: 1,
  },
  {
    dist_id: 17,
    city_id: 2,
    district_name: "Besni",
    status: 1,
  },
  {
    dist_id: 18,
    city_id: 2,
    district_name: "Çelikhan",
    status: 1,
  },
  {
    dist_id: 19,
    city_id: 2,
    district_name: "Gerger",
    status: 1,
  },
  {
    dist_id: 20,
    city_id: 2,
    district_name: "Gölbası",
    status: 1,
  },
  {
    dist_id: 21,
    city_id: 2,
    district_name: "Kahta",
    status: 1,
  },
  {
    dist_id: 22,
    city_id: 2,
    district_name: "Samsat",
    status: 1,
  },
  {
    dist_id: 23,
    city_id: 2,
    district_name: "Sincik",
    status: 1,
  },
  {
    dist_id: 24,
    city_id: 2,
    district_name: "Tut",
    status: 1,
  },
  {
    dist_id: 25,
    city_id: 3,
    district_name: "Afyonkarahisar",
    status: 1,
  },
  {
    dist_id: 26,
    city_id: 3,
    district_name: "Basmakçı",
    status: 1,
  },
  {
    dist_id: 27,
    city_id: 3,
    district_name: "Bayat",
    status: 1,
  },
  {
    dist_id: 28,
    city_id: 3,
    district_name: "Bolvadin",
    status: 1,
  },
  {
    dist_id: 29,
    city_id: 3,
    district_name: "Çay",
    status: 1,
  },
  {
    dist_id: 30,
    city_id: 3,
    district_name: "Çobanlar",
    status: 1,
  },
  {
    dist_id: 31,
    city_id: 3,
    district_name: "Dazkırı",
    status: 1,
  },
  {
    dist_id: 32,
    city_id: 3,
    district_name: "Dinar",
    status: 1,
  },
  {
    dist_id: 33,
    city_id: 3,
    district_name: "Emirdag",
    status: 1,
  },
  {
    dist_id: 34,
    city_id: 3,
    district_name: "Evciler",
    status: 1,
  },
  {
    dist_id: 35,
    city_id: 3,
    district_name: "Hocalar",
    status: 1,
  },
  {
    dist_id: 36,
    city_id: 3,
    district_name: "Ihsaniye",
    status: 1,
  },
  {
    dist_id: 37,
    city_id: 3,
    district_name: "Iscehisar",
    status: 1,
  },
  {
    dist_id: 38,
    city_id: 3,
    district_name: "Kızılören",
    status: 1,
  },
  {
    dist_id: 39,
    city_id: 3,
    district_name: "Sandıklı",
    status: 1,
  },
  {
    dist_id: 40,
    city_id: 3,
    district_name: "Sincanlı(Sinanpasa)",
    status: 1,
  },
  {
    dist_id: 41,
    city_id: 3,
    district_name: "Sultandagı",
    status: 1,
  },
  {
    dist_id: 42,
    city_id: 3,
    district_name: "Suhut",
    status: 1,
  },
  {
    dist_id: 43,
    city_id: 4,
    district_name: "Agrı",
    status: 1,
  },
  {
    dist_id: 44,
    city_id: 4,
    district_name: "Diyadin",
    status: 1,
  },
  {
    dist_id: 45,
    city_id: 4,
    district_name: "Dogubeyazıt",
    status: 1,
  },
  {
    dist_id: 46,
    city_id: 4,
    district_name: "Eleskirt",
    status: 1,
  },
  {
    dist_id: 47,
    city_id: 4,
    district_name: "Hamur",
    status: 1,
  },
  {
    dist_id: 48,
    city_id: 4,
    district_name: "Patnos",
    status: 1,
  },
  {
    dist_id: 49,
    city_id: 4,
    district_name: "Taslıçay",
    status: 1,
  },
  {
    dist_id: 50,
    city_id: 4,
    district_name: "Tutak",
    status: 1,
  },
  {
    dist_id: 51,
    city_id: 5,
    district_name: "Amasya",
    status: 1,
  },
  {
    dist_id: 52,
    city_id: 5,
    district_name: "Göynücek",
    status: 1,
  },
  {
    dist_id: 53,
    city_id: 5,
    district_name: "Gümüshacıköy",
    status: 1,
  },
  {
    dist_id: 54,
    city_id: 5,
    district_name: "Hamamözü",
    status: 1,
  },
  {
    dist_id: 55,
    city_id: 5,
    district_name: "Merzifon",
    status: 1,
  },
  {
    dist_id: 56,
    city_id: 5,
    district_name: "Suluova",
    status: 1,
  },
  {
    dist_id: 57,
    city_id: 5,
    district_name: "Tasova",
    status: 1,
  },
  {
    dist_id: 58,
    city_id: 6,
    district_name: "Altındag",
    status: 1,
  },
  {
    dist_id: 59,
    city_id: 6,
    district_name: "Çankaya",
    status: 1,
  },
  {
    dist_id: 60,
    city_id: 6,
    district_name: "Etimesgut",
    status: 1,
  },
  {
    dist_id: 61,
    city_id: 6,
    district_name: "Keçiören",
    status: 1,
  },
  {
    dist_id: 62,
    city_id: 6,
    district_name: "Mamak",
    status: 1,
  },
  {
    dist_id: 63,
    city_id: 6,
    district_name: "Sincan",
    status: 1,
  },
  {
    dist_id: 64,
    city_id: 6,
    district_name: "Yenimahalle",
    status: 1,
  },
  {
    dist_id: 65,
    city_id: 6,
    district_name: "Gölbası",
    status: 1,
  },
  {
    dist_id: 66,
    city_id: 6,
    district_name: "Pursaklar",
    status: 1,
  },
  {
    dist_id: 67,
    city_id: 6,
    district_name: "Akyurt",
    status: 1,
  },
  {
    dist_id: 68,
    city_id: 6,
    district_name: "Ayas",
    status: 1,
  },
  {
    dist_id: 69,
    city_id: 6,
    district_name: "Bala",
    status: 1,
  },
  {
    dist_id: 70,
    city_id: 6,
    district_name: "Beypazarı",
    status: 1,
  },
  {
    dist_id: 71,
    city_id: 6,
    district_name: "Çamlıdere",
    status: 1,
  },
  {
    dist_id: 72,
    city_id: 6,
    district_name: "Çubuk",
    status: 1,
  },
  {
    dist_id: 73,
    city_id: 6,
    district_name: "Elmadag",
    status: 1,
  },
  {
    dist_id: 74,
    city_id: 6,
    district_name: "Evren",
    status: 1,
  },
  {
    dist_id: 75,
    city_id: 6,
    district_name: "Güdül",
    status: 1,
  },
  {
    dist_id: 76,
    city_id: 6,
    district_name: "Haymana",
    status: 1,
  },
  {
    dist_id: 77,
    city_id: 6,
    district_name: "Kalecik",
    status: 1,
  },
  {
    dist_id: 78,
    city_id: 6,
    district_name: "Kazan",
    status: 1,
  },
  {
    dist_id: 79,
    city_id: 6,
    district_name: "Kızılcahamam",
    status: 1,
  },
  {
    dist_id: 80,
    city_id: 6,
    district_name: "Nallıhan",
    status: 1,
  },
  {
    dist_id: 81,
    city_id: 6,
    district_name: "Polatlı",
    status: 1,
  },
  {
    dist_id: 82,
    city_id: 6,
    district_name: "Sereflikoçhisar",
    status: 1,
  },
  {
    dist_id: 83,
    city_id: 7,
    district_name: "Muratpasa",
    status: 1,
  },
  {
    dist_id: 84,
    city_id: 7,
    district_name: "Kepez",
    status: 1,
  },
  {
    dist_id: 85,
    city_id: 7,
    district_name: "Konyaaltı",
    status: 1,
  },
  {
    dist_id: 86,
    city_id: 7,
    district_name: "Aksu",
    status: 1,
  },
  {
    dist_id: 87,
    city_id: 7,
    district_name: "Dösemealtı",
    status: 1,
  },
  {
    dist_id: 88,
    city_id: 7,
    district_name: "Akseki",
    status: 1,
  },
  {
    dist_id: 89,
    city_id: 7,
    district_name: "Alanya",
    status: 1,
  },
  {
    dist_id: 90,
    city_id: 7,
    district_name: "Elmalı",
    status: 1,
  },
  {
    dist_id: 91,
    city_id: 7,
    district_name: "Finike",
    status: 1,
  },
  {
    dist_id: 92,
    city_id: 7,
    district_name: "Gazipasa",
    status: 1,
  },
  {
    dist_id: 93,
    city_id: 7,
    district_name: "Gündogmus",
    status: 1,
  },
  {
    dist_id: 94,
    city_id: 7,
    district_name: "Ibradı(Aydınkent)",
    status: 1,
  },
  {
    dist_id: 95,
    city_id: 7,
    district_name: "Kale(Demre)",
    status: 1,
  },
  {
    dist_id: 96,
    city_id: 7,
    district_name: "Kas",
    status: 1,
  },
  {
    dist_id: 97,
    city_id: 7,
    district_name: "Kemer",
    status: 1,
  },
  {
    dist_id: 98,
    city_id: 7,
    district_name: "Korkuteli",
    status: 1,
  },
  {
    dist_id: 99,
    city_id: 7,
    district_name: "Kumluca",
    status: 1,
  },
  {
    dist_id: 100,
    city_id: 7,
    district_name: "Manavgat",
    status: 1,
  },
  {
    dist_id: 101,
    city_id: 7,
    district_name: "Serik",
    status: 1,
  },
  {
    dist_id: 102,
    city_id: 8,
    district_name: "Artvin",
    status: 1,
  },
  {
    dist_id: 103,
    city_id: 8,
    district_name: "Ardanuç",
    status: 1,
  },
  {
    dist_id: 104,
    city_id: 8,
    district_name: "Arhavi",
    status: 1,
  },
  {
    dist_id: 105,
    city_id: 8,
    district_name: "Borçka",
    status: 1,
  },
  {
    dist_id: 106,
    city_id: 8,
    district_name: "Hopa",
    status: 1,
  },
  {
    dist_id: 107,
    city_id: 8,
    district_name: "Murgul(Göktas)",
    status: 1,
  },
  {
    dist_id: 108,
    city_id: 8,
    district_name: "Savsat",
    status: 1,
  },
  {
    dist_id: 109,
    city_id: 8,
    district_name: "Yusufeli",
    status: 1,
  },
  {
    dist_id: 110,
    city_id: 9,
    district_name: "Efeler",
    status: 1,
  },
  {
    dist_id: 111,
    city_id: 9,
    district_name: "Bozdogan",
    status: 1,
  },
  {
    dist_id: 112,
    city_id: 9,
    district_name: "Buharkent(Çubukdagı)",
    status: 1,
  },
  {
    dist_id: 113,
    city_id: 9,
    district_name: "Çine",
    status: 1,
  },
  {
    dist_id: 114,
    city_id: 9,
    district_name: "Germencik",
    status: 1,
  },
  {
    dist_id: 115,
    city_id: 9,
    district_name: "Incirliova",
    status: 1,
  },
  {
    dist_id: 116,
    city_id: 9,
    district_name: "Karacasu",
    status: 1,
  },
  {
    dist_id: 117,
    city_id: 9,
    district_name: "Karpuzlu",
    status: 1,
  },
  {
    dist_id: 118,
    city_id: 9,
    district_name: "Koçarlı",
    status: 1,
  },
  {
    dist_id: 119,
    city_id: 9,
    district_name: "Kösk",
    status: 1,
  },
  {
    dist_id: 120,
    city_id: 9,
    district_name: "Kusadası",
    status: 1,
  },
  {
    dist_id: 121,
    city_id: 9,
    district_name: "Kuyucak",
    status: 1,
  },
  {
    dist_id: 122,
    city_id: 9,
    district_name: "Nazilli",
    status: 1,
  },
  {
    dist_id: 123,
    city_id: 9,
    district_name: "Söke",
    status: 1,
  },
  {
    dist_id: 124,
    city_id: 9,
    district_name: "Sultanhisar",
    status: 1,
  },
  {
    dist_id: 125,
    city_id: 9,
    district_name: "Didim",
    status: 1,
  },
  {
    dist_id: 126,
    city_id: 9,
    district_name: "Yenipazar",
    status: 1,
  },
  {
    dist_id: 127,
    city_id: 10,
    district_name: "Balıkesir",
    status: 1,
  },
  {
    dist_id: 128,
    city_id: 10,
    district_name: "Ayvalık",
    status: 1,
  },
  {
    dist_id: 129,
    city_id: 10,
    district_name: "Balya",
    status: 1,
  },
  {
    dist_id: 130,
    city_id: 10,
    district_name: "Bandırma",
    status: 1,
  },
  {
    dist_id: 131,
    city_id: 10,
    district_name: "Bigadiç",
    status: 1,
  },
  {
    dist_id: 132,
    city_id: 10,
    district_name: "Burhaniye",
    status: 1,
  },
  {
    dist_id: 133,
    city_id: 10,
    district_name: "Dursunbey",
    status: 1,
  },
  {
    dist_id: 134,
    city_id: 10,
    district_name: "Edremit",
    status: 1,
  },
  {
    dist_id: 135,
    city_id: 10,
    district_name: "Erdek",
    status: 1,
  },
  {
    dist_id: 136,
    city_id: 10,
    district_name: "Gömeç",
    status: 1,
  },
  {
    dist_id: 137,
    city_id: 10,
    district_name: "Gönen",
    status: 1,
  },
  {
    dist_id: 138,
    city_id: 10,
    district_name: "Havran",
    status: 1,
  },
  {
    dist_id: 139,
    city_id: 10,
    district_name: "Ivrindi",
    status: 1,
  },
  {
    dist_id: 140,
    city_id: 10,
    district_name: "Kepsut",
    status: 1,
  },
  {
    dist_id: 141,
    city_id: 10,
    district_name: "Manyas",
    status: 1,
  },
  {
    dist_id: 142,
    city_id: 10,
    district_name: "Marmara",
    status: 1,
  },
  {
    dist_id: 143,
    city_id: 10,
    district_name: "Savastepe",
    status: 1,
  },
  {
    dist_id: 144,
    city_id: 10,
    district_name: "Sındırgı",
    status: 1,
  },
  {
    dist_id: 145,
    city_id: 10,
    district_name: "Susurluk",
    status: 1,
  },
  {
    dist_id: 146,
    city_id: 11,
    district_name: "Bilecik",
    status: 1,
  },
  {
    dist_id: 147,
    city_id: 11,
    district_name: "Bozüyük",
    status: 1,
  },
  {
    dist_id: 148,
    city_id: 11,
    district_name: "Gölpazarı",
    status: 1,
  },
  {
    dist_id: 149,
    city_id: 11,
    district_name: "Inhisar",
    status: 1,
  },
  {
    dist_id: 150,
    city_id: 11,
    district_name: "Osmaneli",
    status: 1,
  },
  {
    dist_id: 151,
    city_id: 11,
    district_name: "Pazaryeri",
    status: 1,
  },
  {
    dist_id: 152,
    city_id: 11,
    district_name: "Sögüt",
    status: 1,
  },
  {
    dist_id: 153,
    city_id: 11,
    district_name: "Yenipazar",
    status: 1,
  },
  {
    dist_id: 154,
    city_id: 12,
    district_name: "Bingöl",
    status: 1,
  },
  {
    dist_id: 155,
    city_id: 12,
    district_name: "Adaklı",
    status: 1,
  },
  {
    dist_id: 156,
    city_id: 12,
    district_name: "Genç",
    status: 1,
  },
  {
    dist_id: 157,
    city_id: 12,
    district_name: "Karlıova",
    status: 1,
  },
  {
    dist_id: 158,
    city_id: 12,
    district_name: "Kıgı",
    status: 1,
  },
  {
    dist_id: 159,
    city_id: 12,
    district_name: "Solhan",
    status: 1,
  },
  {
    dist_id: 160,
    city_id: 12,
    district_name: "Yayladere",
    status: 1,
  },
  {
    dist_id: 161,
    city_id: 12,
    district_name: "Yedisu",
    status: 1,
  },
  {
    dist_id: 162,
    city_id: 13,
    district_name: "Bitlis",
    status: 1,
  },
  {
    dist_id: 163,
    city_id: 13,
    district_name: "Adilcevaz",
    status: 1,
  },
  {
    dist_id: 164,
    city_id: 13,
    district_name: "Ahlat",
    status: 1,
  },
  {
    dist_id: 165,
    city_id: 13,
    district_name: "Güroymak",
    status: 1,
  },
  {
    dist_id: 166,
    city_id: 13,
    district_name: "Hizan",
    status: 1,
  },
  {
    dist_id: 167,
    city_id: 13,
    district_name: "Mutki",
    status: 1,
  },
  {
    dist_id: 168,
    city_id: 13,
    district_name: "Tatvan",
    status: 1,
  },
  {
    dist_id: 169,
    city_id: 14,
    district_name: "Bolu",
    status: 1,
  },
  {
    dist_id: 170,
    city_id: 14,
    district_name: "Dörtdivan",
    status: 1,
  },
  {
    dist_id: 171,
    city_id: 14,
    district_name: "Gerede",
    status: 1,
  },
  {
    dist_id: 172,
    city_id: 14,
    district_name: "Göynük",
    status: 1,
  },
  {
    dist_id: 173,
    city_id: 14,
    district_name: "Kıbrıscık",
    status: 1,
  },
  {
    dist_id: 174,
    city_id: 14,
    district_name: "Mengen",
    status: 1,
  },
  {
    dist_id: 175,
    city_id: 14,
    district_name: "Mudurnu",
    status: 1,
  },
  {
    dist_id: 176,
    city_id: 14,
    district_name: "Seben",
    status: 1,
  },
  {
    dist_id: 177,
    city_id: 14,
    district_name: "Yeniçaga",
    status: 1,
  },
  {
    dist_id: 178,
    city_id: 15,
    district_name: "Burdur",
    status: 1,
  },
  {
    dist_id: 179,
    city_id: 15,
    district_name: "Aglasun",
    status: 1,
  },
  {
    dist_id: 180,
    city_id: 15,
    district_name: "Altınyayla(Dirmil)",
    status: 1,
  },
  {
    dist_id: 181,
    city_id: 15,
    district_name: "Bucak",
    status: 1,
  },
  {
    dist_id: 182,
    city_id: 15,
    district_name: "Çavdır",
    status: 1,
  },
  {
    dist_id: 183,
    city_id: 15,
    district_name: "Çeltikçi",
    status: 1,
  },
  {
    dist_id: 184,
    city_id: 15,
    district_name: "Gölhisar",
    status: 1,
  },
  {
    dist_id: 185,
    city_id: 15,
    district_name: "Karamanlı",
    status: 1,
  },
  {
    dist_id: 186,
    city_id: 15,
    district_name: "Kemer",
    status: 1,
  },
  {
    dist_id: 187,
    city_id: 15,
    district_name: "Tefenni",
    status: 1,
  },
  {
    dist_id: 188,
    city_id: 15,
    district_name: "Yesilova",
    status: 1,
  },
  {
    dist_id: 189,
    city_id: 16,
    district_name: "Nilüfer",
    status: 1,
  },
  {
    dist_id: 190,
    city_id: 16,
    district_name: "Osmangazi",
    status: 1,
  },
  {
    dist_id: 191,
    city_id: 16,
    district_name: "Yıldırım",
    status: 1,
  },
  {
    dist_id: 192,
    city_id: 16,
    district_name: "Büyükorhan",
    status: 1,
  },
  {
    dist_id: 193,
    city_id: 16,
    district_name: "Gemlik",
    status: 1,
  },
  {
    dist_id: 194,
    city_id: 16,
    district_name: "Gürsu",
    status: 1,
  },
  {
    dist_id: 195,
    city_id: 16,
    district_name: "Harmancık",
    status: 1,
  },
  {
    dist_id: 196,
    city_id: 16,
    district_name: "Inegöl",
    status: 1,
  },
  {
    dist_id: 197,
    city_id: 16,
    district_name: "Iznik",
    status: 1,
  },
  {
    dist_id: 198,
    city_id: 16,
    district_name: "Karacabey",
    status: 1,
  },
  {
    dist_id: 199,
    city_id: 16,
    district_name: "Keles",
    status: 1,
  },
  {
    dist_id: 200,
    city_id: 16,
    district_name: "Kestel",
    status: 1,
  },
  {
    dist_id: 201,
    city_id: 16,
    district_name: "Mudanya",
    status: 1,
  },
  {
    dist_id: 202,
    city_id: 16,
    district_name: "Mustafakemalpasa",
    status: 1,
  },
  {
    dist_id: 203,
    city_id: 16,
    district_name: "Orhaneli",
    status: 1,
  },
  {
    dist_id: 204,
    city_id: 16,
    district_name: "Orhangazi",
    status: 1,
  },
  {
    dist_id: 205,
    city_id: 16,
    district_name: "Yenisehir",
    status: 1,
  },
  {
    dist_id: 206,
    city_id: 17,
    district_name: "Çanakkale",
    status: 1,
  },
  {
    dist_id: 207,
    city_id: 17,
    district_name: "Ayvacık-Assos",
    status: 1,
  },
  {
    dist_id: 208,
    city_id: 17,
    district_name: "Bayramiç",
    status: 1,
  },
  {
    dist_id: 209,
    city_id: 17,
    district_name: "Biga",
    status: 1,
  },
  {
    dist_id: 210,
    city_id: 17,
    district_name: "Bozcaada",
    status: 1,
  },
  {
    dist_id: 211,
    city_id: 17,
    district_name: "Çan",
    status: 1,
  },
  {
    dist_id: 212,
    city_id: 17,
    district_name: "Eceabat",
    status: 1,
  },
  {
    dist_id: 213,
    city_id: 17,
    district_name: "Ezine",
    status: 1,
  },
  {
    dist_id: 214,
    city_id: 17,
    district_name: "Gelibolu",
    status: 1,
  },
  {
    dist_id: 215,
    city_id: 17,
    district_name: "Gökçeada(Imroz)",
    status: 1,
  },
  {
    dist_id: 216,
    city_id: 17,
    district_name: "Lapseki",
    status: 1,
  },
  {
    dist_id: 217,
    city_id: 17,
    district_name: "Yenice",
    status: 1,
  },
  {
    dist_id: 218,
    city_id: 18,
    district_name: "Çankırı",
    status: 1,
  },
  {
    dist_id: 219,
    city_id: 18,
    district_name: "Atkaracalar",
    status: 1,
  },
  {
    dist_id: 220,
    city_id: 18,
    district_name: "Bayramören",
    status: 1,
  },
  {
    dist_id: 221,
    city_id: 18,
    district_name: "Çerkes",
    status: 1,
  },
  {
    dist_id: 222,
    city_id: 18,
    district_name: "Eldivan",
    status: 1,
  },
  {
    dist_id: 223,
    city_id: 18,
    district_name: "Ilgaz",
    status: 1,
  },
  {
    dist_id: 224,
    city_id: 18,
    district_name: "Kızılırmak",
    status: 1,
  },
  {
    dist_id: 225,
    city_id: 18,
    district_name: "Korgun",
    status: 1,
  },
  {
    dist_id: 226,
    city_id: 18,
    district_name: "Kursunlu",
    status: 1,
  },
  {
    dist_id: 227,
    city_id: 18,
    district_name: "Orta",
    status: 1,
  },
  {
    dist_id: 228,
    city_id: 18,
    district_name: "Sabanözü",
    status: 1,
  },
  {
    dist_id: 229,
    city_id: 18,
    district_name: "Yapraklı",
    status: 1,
  },
  {
    dist_id: 230,
    city_id: 19,
    district_name: "Çorum",
    status: 1,
  },
  {
    dist_id: 231,
    city_id: 19,
    district_name: "Alaca",
    status: 1,
  },
  {
    dist_id: 232,
    city_id: 19,
    district_name: "Bayat",
    status: 1,
  },
  {
    dist_id: 233,
    city_id: 19,
    district_name: "Bogazkale",
    status: 1,
  },
  {
    dist_id: 234,
    city_id: 19,
    district_name: "Dodurga",
    status: 1,
  },
  {
    dist_id: 235,
    city_id: 19,
    district_name: "Iskilip",
    status: 1,
  },
  {
    dist_id: 236,
    city_id: 19,
    district_name: "Kargı",
    status: 1,
  },
  {
    dist_id: 237,
    city_id: 19,
    district_name: "Laçin",
    status: 1,
  },
  {
    dist_id: 238,
    city_id: 19,
    district_name: "Mecitözü",
    status: 1,
  },
  {
    dist_id: 239,
    city_id: 19,
    district_name: "Oguzlar(Karaören)",
    status: 1,
  },
  {
    dist_id: 240,
    city_id: 19,
    district_name: "Ortaköy",
    status: 1,
  },
  {
    dist_id: 241,
    city_id: 19,
    district_name: "Osmancık",
    status: 1,
  },
  {
    dist_id: 242,
    city_id: 19,
    district_name: "Sungurlu",
    status: 1,
  },
  {
    dist_id: 243,
    city_id: 19,
    district_name: "Ugurludag",
    status: 1,
  },
  {
    dist_id: 244,
    city_id: 20,
    district_name: "Denizli",
    status: 1,
  },
  {
    dist_id: 245,
    city_id: 20,
    district_name: "Acıpayam",
    status: 1,
  },
  {
    dist_id: 246,
    city_id: 20,
    district_name: "Akköy",
    status: 1,
  },
  {
    dist_id: 247,
    city_id: 20,
    district_name: "Babadag",
    status: 1,
  },
  {
    dist_id: 248,
    city_id: 20,
    district_name: "Baklan",
    status: 1,
  },
  {
    dist_id: 249,
    city_id: 20,
    district_name: "Bekilli",
    status: 1,
  },
  {
    dist_id: 250,
    city_id: 20,
    district_name: "Beyagaç",
    status: 1,
  },
  {
    dist_id: 251,
    city_id: 20,
    district_name: "Bozkurt",
    status: 1,
  },
  {
    dist_id: 252,
    city_id: 20,
    district_name: "Buldan",
    status: 1,
  },
  {
    dist_id: 253,
    city_id: 20,
    district_name: "Çal",
    status: 1,
  },
  {
    dist_id: 254,
    city_id: 20,
    district_name: "Çameli",
    status: 1,
  },
  {
    dist_id: 255,
    city_id: 20,
    district_name: "Çardak",
    status: 1,
  },
  {
    dist_id: 256,
    city_id: 20,
    district_name: "Çivril",
    status: 1,
  },
  {
    dist_id: 257,
    city_id: 20,
    district_name: "Güney",
    status: 1,
  },
  {
    dist_id: 258,
    city_id: 20,
    district_name: "Honaz",
    status: 1,
  },
  {
    dist_id: 259,
    city_id: 20,
    district_name: "Kale",
    status: 1,
  },
  {
    dist_id: 260,
    city_id: 20,
    district_name: "Sarayköy",
    status: 1,
  },
  {
    dist_id: 261,
    city_id: 20,
    district_name: "Serinhisar",
    status: 1,
  },
  {
    dist_id: 262,
    city_id: 20,
    district_name: "Tavas",
    status: 1,
  },
  {
    dist_id: 263,
    city_id: 21,
    district_name: "Sur",
    status: 1,
  },
  {
    dist_id: 264,
    city_id: 21,
    district_name: "Baglar",
    status: 1,
  },
  {
    dist_id: 265,
    city_id: 21,
    district_name: "Yenisehir",
    status: 1,
  },
  {
    dist_id: 266,
    city_id: 21,
    district_name: "Kayapınar",
    status: 1,
  },
  {
    dist_id: 267,
    city_id: 21,
    district_name: "Bismil",
    status: 1,
  },
  {
    dist_id: 268,
    city_id: 21,
    district_name: "Çermik",
    status: 1,
  },
  {
    dist_id: 269,
    city_id: 21,
    district_name: "Çınar",
    status: 1,
  },
  {
    dist_id: 270,
    city_id: 21,
    district_name: "Çüngüs",
    status: 1,
  },
  {
    dist_id: 271,
    city_id: 21,
    district_name: "Dicle",
    status: 1,
  },
  {
    dist_id: 272,
    city_id: 21,
    district_name: "Egil",
    status: 1,
  },
  {
    dist_id: 273,
    city_id: 21,
    district_name: "Ergani",
    status: 1,
  },
  {
    dist_id: 274,
    city_id: 21,
    district_name: "Hani",
    status: 1,
  },
  {
    dist_id: 275,
    city_id: 21,
    district_name: "Hazro",
    status: 1,
  },
  {
    dist_id: 276,
    city_id: 21,
    district_name: "Kocaköy",
    status: 1,
  },
  {
    dist_id: 277,
    city_id: 21,
    district_name: "Kulp",
    status: 1,
  },
  {
    dist_id: 278,
    city_id: 21,
    district_name: "Lice",
    status: 1,
  },
  {
    dist_id: 279,
    city_id: 21,
    district_name: "Silvan",
    status: 1,
  },
  {
    dist_id: 280,
    city_id: 22,
    district_name: "Edirne",
    status: 1,
  },
  {
    dist_id: 281,
    city_id: 22,
    district_name: "Enez",
    status: 1,
  },
  {
    dist_id: 282,
    city_id: 22,
    district_name: "Havsa",
    status: 1,
  },
  {
    dist_id: 283,
    city_id: 22,
    district_name: "Ipsala",
    status: 1,
  },
  {
    dist_id: 284,
    city_id: 22,
    district_name: "Kesan",
    status: 1,
  },
  {
    dist_id: 285,
    city_id: 22,
    district_name: "Lalapasa",
    status: 1,
  },
  {
    dist_id: 286,
    city_id: 22,
    district_name: "Meriç",
    status: 1,
  },
  {
    dist_id: 287,
    city_id: 22,
    district_name: "Süleoglu(Süloglu)",
    status: 1,
  },
  {
    dist_id: 288,
    city_id: 22,
    district_name: "Uzunköprü",
    status: 1,
  },
  {
    dist_id: 289,
    city_id: 23,
    district_name: "Elazıg",
    status: 1,
  },
  {
    dist_id: 290,
    city_id: 23,
    district_name: "Agın",
    status: 1,
  },
  {
    dist_id: 291,
    city_id: 23,
    district_name: "Alacakaya",
    status: 1,
  },
  {
    dist_id: 292,
    city_id: 23,
    district_name: "Arıcak",
    status: 1,
  },
  {
    dist_id: 293,
    city_id: 23,
    district_name: "Baskil",
    status: 1,
  },
  {
    dist_id: 294,
    city_id: 23,
    district_name: "Karakoçan",
    status: 1,
  },
  {
    dist_id: 295,
    city_id: 23,
    district_name: "Keban",
    status: 1,
  },
  {
    dist_id: 296,
    city_id: 23,
    district_name: "Kovancılar",
    status: 1,
  },
  {
    dist_id: 297,
    city_id: 23,
    district_name: "Maden",
    status: 1,
  },
  {
    dist_id: 298,
    city_id: 23,
    district_name: "Palu",
    status: 1,
  },
  {
    dist_id: 299,
    city_id: 23,
    district_name: "Sivrice",
    status: 1,
  },
  {
    dist_id: 300,
    city_id: 24,
    district_name: "Erzincan",
    status: 1,
  },
  {
    dist_id: 301,
    city_id: 24,
    district_name: "Çayırlı",
    status: 1,
  },
  {
    dist_id: 302,
    city_id: 24,
    district_name: "Iliç(Ilıç)",
    status: 1,
  },
  {
    dist_id: 303,
    city_id: 24,
    district_name: "Kemah",
    status: 1,
  },
  {
    dist_id: 304,
    city_id: 24,
    district_name: "Kemaliye",
    status: 1,
  },
  {
    dist_id: 305,
    city_id: 24,
    district_name: "Otlukbeli",
    status: 1,
  },
  {
    dist_id: 306,
    city_id: 24,
    district_name: "Refahiye",
    status: 1,
  },
  {
    dist_id: 307,
    city_id: 24,
    district_name: "Tercan",
    status: 1,
  },
  {
    dist_id: 308,
    city_id: 24,
    district_name: "Üzümlü",
    status: 1,
  },
  {
    dist_id: 309,
    city_id: 25,
    district_name: "Palandöken",
    status: 1,
  },
  {
    dist_id: 310,
    city_id: 25,
    district_name: "Yakutiye",
    status: 1,
  },
  {
    dist_id: 311,
    city_id: 25,
    district_name: "Aziziye(Ilıca)",
    status: 1,
  },
  {
    dist_id: 312,
    city_id: 25,
    district_name: "Askale",
    status: 1,
  },
  {
    dist_id: 313,
    city_id: 25,
    district_name: "Çat",
    status: 1,
  },
  {
    dist_id: 314,
    city_id: 25,
    district_name: "Hınıs",
    status: 1,
  },
  {
    dist_id: 315,
    city_id: 25,
    district_name: "Horasan",
    status: 1,
  },
  {
    dist_id: 316,
    city_id: 25,
    district_name: "Ispir",
    status: 1,
  },
  {
    dist_id: 317,
    city_id: 25,
    district_name: "Karaçoban",
    status: 1,
  },
  {
    dist_id: 318,
    city_id: 25,
    district_name: "Karayazı",
    status: 1,
  },
  {
    dist_id: 319,
    city_id: 25,
    district_name: "Köprüköy",
    status: 1,
  },
  {
    dist_id: 320,
    city_id: 25,
    district_name: "Narman",
    status: 1,
  },
  {
    dist_id: 321,
    city_id: 25,
    district_name: "Oltu",
    status: 1,
  },
  {
    dist_id: 322,
    city_id: 25,
    district_name: "Olur",
    status: 1,
  },
  {
    dist_id: 323,
    city_id: 25,
    district_name: "Pasinler",
    status: 1,
  },
  {
    dist_id: 324,
    city_id: 25,
    district_name: "Pazaryolu",
    status: 1,
  },
  {
    dist_id: 325,
    city_id: 25,
    district_name: "Senkaya",
    status: 1,
  },
  {
    dist_id: 326,
    city_id: 25,
    district_name: "Tekman",
    status: 1,
  },
  {
    dist_id: 327,
    city_id: 25,
    district_name: "Tortum",
    status: 1,
  },
  {
    dist_id: 328,
    city_id: 25,
    district_name: "Uzundere",
    status: 1,
  },
  {
    dist_id: 329,
    city_id: 26,
    district_name: "Odunpazarı",
    status: 1,
  },
  {
    dist_id: 330,
    city_id: 26,
    district_name: "Tepebası",
    status: 1,
  },
  {
    dist_id: 331,
    city_id: 26,
    district_name: "Alpu",
    status: 1,
  },
  {
    dist_id: 332,
    city_id: 26,
    district_name: "Beylikova",
    status: 1,
  },
  {
    dist_id: 333,
    city_id: 26,
    district_name: "Çifteler",
    status: 1,
  },
  {
    dist_id: 334,
    city_id: 26,
    district_name: "Günyüzü",
    status: 1,
  },
  {
    dist_id: 335,
    city_id: 26,
    district_name: "Han",
    status: 1,
  },
  {
    dist_id: 336,
    city_id: 26,
    district_name: "Inönü",
    status: 1,
  },
  {
    dist_id: 337,
    city_id: 26,
    district_name: "Mahmudiye",
    status: 1,
  },
  {
    dist_id: 338,
    city_id: 26,
    district_name: "Mihalgazi",
    status: 1,
  },
  {
    dist_id: 339,
    city_id: 26,
    district_name: "Mihalıçcık",
    status: 1,
  },
  {
    dist_id: 340,
    city_id: 26,
    district_name: "Sarıcakaya",
    status: 1,
  },
  {
    dist_id: 341,
    city_id: 26,
    district_name: "Seyitgazi",
    status: 1,
  },
  {
    dist_id: 342,
    city_id: 26,
    district_name: "Sivrihisar",
    status: 1,
  },
  {
    dist_id: 343,
    city_id: 27,
    district_name: "Sahinbey",
    status: 1,
  },
  {
    dist_id: 344,
    city_id: 27,
    district_name: "Sehitkamil",
    status: 1,
  },
  {
    dist_id: 345,
    city_id: 27,
    district_name: "Oguzeli",
    status: 1,
  },
  {
    dist_id: 346,
    city_id: 27,
    district_name: "Araban",
    status: 1,
  },
  {
    dist_id: 347,
    city_id: 27,
    district_name: "Islahiye",
    status: 1,
  },
  {
    dist_id: 348,
    city_id: 27,
    district_name: "Karkamıs",
    status: 1,
  },
  {
    dist_id: 349,
    city_id: 27,
    district_name: "Nizip",
    status: 1,
  },
  {
    dist_id: 350,
    city_id: 27,
    district_name: "Nurdagı",
    status: 1,
  },
  {
    dist_id: 351,
    city_id: 27,
    district_name: "Yavuzeli",
    status: 1,
  },
  {
    dist_id: 352,
    city_id: 28,
    district_name: "Giresun",
    status: 1,
  },
  {
    dist_id: 353,
    city_id: 28,
    district_name: "Alucra",
    status: 1,
  },
  {
    dist_id: 354,
    city_id: 28,
    district_name: "Bulancak",
    status: 1,
  },
  {
    dist_id: 355,
    city_id: 28,
    district_name: "Çamoluk",
    status: 1,
  },
  {
    dist_id: 356,
    city_id: 28,
    district_name: "Çanakçı",
    status: 1,
  },
  {
    dist_id: 357,
    city_id: 28,
    district_name: "Dereli",
    status: 1,
  },
  {
    dist_id: 358,
    city_id: 28,
    district_name: "Dogankent",
    status: 1,
  },
  {
    dist_id: 359,
    city_id: 28,
    district_name: "Espiye",
    status: 1,
  },
  {
    dist_id: 360,
    city_id: 28,
    district_name: "Eynesil",
    status: 1,
  },
  {
    dist_id: 361,
    city_id: 28,
    district_name: "Görele",
    status: 1,
  },
  {
    dist_id: 362,
    city_id: 28,
    district_name: "Güce",
    status: 1,
  },
  {
    dist_id: 363,
    city_id: 28,
    district_name: "Kesap",
    status: 1,
  },
  {
    dist_id: 364,
    city_id: 28,
    district_name: "Piraziz",
    status: 1,
  },
  {
    dist_id: 365,
    city_id: 28,
    district_name: "Sebinkarahisar",
    status: 1,
  },
  {
    dist_id: 366,
    city_id: 28,
    district_name: "Tirebolu",
    status: 1,
  },
  {
    dist_id: 367,
    city_id: 28,
    district_name: "Yaglıdere",
    status: 1,
  },
  {
    dist_id: 368,
    city_id: 29,
    district_name: "Gümüshane",
    status: 1,
  },
  {
    dist_id: 369,
    city_id: 29,
    district_name: "Kelkit",
    status: 1,
  },
  {
    dist_id: 370,
    city_id: 29,
    district_name: "Köse",
    status: 1,
  },
  {
    dist_id: 371,
    city_id: 29,
    district_name: "Kürtün",
    status: 1,
  },
  {
    dist_id: 372,
    city_id: 29,
    district_name: "Siran",
    status: 1,
  },
  {
    dist_id: 373,
    city_id: 29,
    district_name: "Torul",
    status: 1,
  },
  {
    dist_id: 374,
    city_id: 30,
    district_name: "Hakkari",
    status: 1,
  },
  {
    dist_id: 375,
    city_id: 30,
    district_name: "Çukurca",
    status: 1,
  },
  {
    dist_id: 376,
    city_id: 30,
    district_name: "Semdinli",
    status: 1,
  },
  {
    dist_id: 377,
    city_id: 30,
    district_name: "Yüksekova",
    status: 1,
  },
  {
    dist_id: 378,
    city_id: 31,
    district_name: "Antakya",
    status: 1,
  },
  {
    dist_id: 379,
    city_id: 31,
    district_name: "Altınözü",
    status: 1,
  },
  {
    dist_id: 380,
    city_id: 31,
    district_name: "Belen",
    status: 1,
  },
  {
    dist_id: 381,
    city_id: 31,
    district_name: "Dörtyol",
    status: 1,
  },
  {
    dist_id: 382,
    city_id: 31,
    district_name: "Erzin",
    status: 1,
  },
  {
    dist_id: 383,
    city_id: 31,
    district_name: "Hassa",
    status: 1,
  },
  {
    dist_id: 384,
    city_id: 31,
    district_name: "Iskenderun",
    status: 1,
  },
  {
    dist_id: 385,
    city_id: 31,
    district_name: "Kırıkhan",
    status: 1,
  },
  {
    dist_id: 386,
    city_id: 31,
    district_name: "Kumlu",
    status: 1,
  },
  {
    dist_id: 387,
    city_id: 31,
    district_name: "Reyhanlı",
    status: 1,
  },
  {
    dist_id: 388,
    city_id: 31,
    district_name: "Samandag",
    status: 1,
  },
  {
    dist_id: 389,
    city_id: 31,
    district_name: "Yayladagı",
    status: 1,
  },
  {
    dist_id: 390,
    city_id: 32,
    district_name: "Isparta",
    status: 1,
  },
  {
    dist_id: 391,
    city_id: 32,
    district_name: "Aksu",
    status: 1,
  },
  {
    dist_id: 392,
    city_id: 32,
    district_name: "Atabey",
    status: 1,
  },
  {
    dist_id: 393,
    city_id: 32,
    district_name: "Egridir(Egirdir)",
    status: 1,
  },
  {
    dist_id: 394,
    city_id: 32,
    district_name: "Gelendost",
    status: 1,
  },
  {
    dist_id: 395,
    city_id: 32,
    district_name: "Gönen",
    status: 1,
  },
  {
    dist_id: 396,
    city_id: 32,
    district_name: "Keçiborlu",
    status: 1,
  },
  {
    dist_id: 397,
    city_id: 32,
    district_name: "Senirkent",
    status: 1,
  },
  {
    dist_id: 398,
    city_id: 32,
    district_name: "Sütçüler",
    status: 1,
  },
  {
    dist_id: 399,
    city_id: 32,
    district_name: "Sarkikaraagaç",
    status: 1,
  },
  {
    dist_id: 400,
    city_id: 32,
    district_name: "Uluborlu",
    status: 1,
  },
  {
    dist_id: 401,
    city_id: 32,
    district_name: "Yalvaç",
    status: 1,
  },
  {
    dist_id: 402,
    city_id: 32,
    district_name: "Yenisarbademli",
    status: 1,
  },
  {
    dist_id: 403,
    city_id: 33,
    district_name: "Akdeniz",
    status: 1,
  },
  {
    dist_id: 404,
    city_id: 33,
    district_name: "Yenisehir",
    status: 1,
  },
  {
    dist_id: 405,
    city_id: 33,
    district_name: "Toroslar",
    status: 1,
  },
  {
    dist_id: 406,
    city_id: 33,
    district_name: "Mezitli",
    status: 1,
  },
  {
    dist_id: 407,
    city_id: 33,
    district_name: "Anamur",
    status: 1,
  },
  {
    dist_id: 408,
    city_id: 33,
    district_name: "Aydıncık",
    status: 1,
  },
  {
    dist_id: 409,
    city_id: 33,
    district_name: "Bozyazı",
    status: 1,
  },
  {
    dist_id: 410,
    city_id: 33,
    district_name: "Çamlıyayla",
    status: 1,
  },
  {
    dist_id: 411,
    city_id: 33,
    district_name: "Erdemli",
    status: 1,
  },
  {
    dist_id: 412,
    city_id: 33,
    district_name: "Gülnar(Gülpınar)",
    status: 1,
  },
  {
    dist_id: 413,
    city_id: 33,
    district_name: "Mut",
    status: 1,
  },
  {
    dist_id: 414,
    city_id: 33,
    district_name: "Silifke",
    status: 1,
  },
  {
    dist_id: 415,
    city_id: 33,
    district_name: "Tarsus",
    status: 1,
  },
  {
    dist_id: 416,
    city_id: 34,
    district_name: "Bakırköy",
    status: 1,
  },
  {
    dist_id: 417,
    city_id: 34,
    district_name: "Bayrampasa",
    status: 1,
  },
  {
    dist_id: 418,
    city_id: 34,
    district_name: "Besiktas",
    status: 1,
  },
  {
    dist_id: 419,
    city_id: 34,
    district_name: "Beyoglu",
    status: 1,
  },
  {
    dist_id: 420,
    city_id: 34,
    district_name: "Arnavutköy",
    status: 1,
  },
  {
    dist_id: 421,
    city_id: 34,
    district_name: "Eyüp",
    status: 1,
  },
  {
    dist_id: 422,
    city_id: 34,
    district_name: "Fatih",
    status: 1,
  },
  {
    dist_id: 423,
    city_id: 34,
    district_name: "Gaziosmanpasa",
    status: 1,
  },
  {
    dist_id: 424,
    city_id: 34,
    district_name: "Kagıthane",
    status: 1,
  },
  {
    dist_id: 425,
    city_id: 34,
    district_name: "Küçükçekmece",
    status: 1,
  },
  {
    dist_id: 426,
    city_id: 34,
    district_name: "Sarıyer",
    status: 1,
  },
  {
    dist_id: 427,
    city_id: 34,
    district_name: "Sisli",
    status: 1,
  },
  {
    dist_id: 428,
    city_id: 34,
    district_name: "Zeytinburnu",
    status: 1,
  },
  {
    dist_id: 429,
    city_id: 34,
    district_name: "Avcılar",
    status: 1,
  },
  {
    dist_id: 430,
    city_id: 34,
    district_name: "Güngören",
    status: 1,
  },
  {
    dist_id: 431,
    city_id: 34,
    district_name: "Bahçelievler",
    status: 1,
  },
  {
    dist_id: 432,
    city_id: 34,
    district_name: "Bagcılar",
    status: 1,
  },
  {
    dist_id: 433,
    city_id: 34,
    district_name: "Esenler",
    status: 1,
  },
  {
    dist_id: 434,
    city_id: 34,
    district_name: "Basaksehir",
    status: 1,
  },
  {
    dist_id: 435,
    city_id: 34,
    district_name: "Beylikdüzü",
    status: 1,
  },
  {
    dist_id: 436,
    city_id: 34,
    district_name: "Esenyurt",
    status: 1,
  },
  {
    dist_id: 437,
    city_id: 34,
    district_name: "Sultangazi",
    status: 1,
  },
  {
    dist_id: 438,
    city_id: 34,
    district_name: "Adalar",
    status: 1,
  },
  {
    dist_id: 439,
    city_id: 34,
    district_name: "Beykoz",
    status: 1,
  },
  {
    dist_id: 440,
    city_id: 34,
    district_name: "Kadıköy",
    status: 1,
  },
  {
    dist_id: 441,
    city_id: 34,
    district_name: "Kartal",
    status: 1,
  },
  {
    dist_id: 442,
    city_id: 34,
    district_name: "Pendik",
    status: 1,
  },
  {
    dist_id: 443,
    city_id: 34,
    district_name: "Ümraniye",
    status: 1,
  },
  {
    dist_id: 444,
    city_id: 34,
    district_name: "Üsküdar",
    status: 1,
  },
  {
    dist_id: 445,
    city_id: 34,
    district_name: "Tuzla",
    status: 1,
  },
  {
    dist_id: 446,
    city_id: 34,
    district_name: "Maltepe",
    status: 1,
  },
  {
    dist_id: 447,
    city_id: 34,
    district_name: "Atasehir",
    status: 1,
  },
  {
    dist_id: 448,
    city_id: 34,
    district_name: "Çekmeköy",
    status: 1,
  },
  {
    dist_id: 449,
    city_id: 34,
    district_name: "Sancaktepe",
    status: 1,
  },
  {
    dist_id: 450,
    city_id: 34,
    district_name: "Büyükçekmece",
    status: 1,
  },
  {
    dist_id: 451,
    city_id: 34,
    district_name: "Çatalca",
    status: 1,
  },
  {
    dist_id: 452,
    city_id: 34,
    district_name: "Silivri",
    status: 1,
  },
  {
    dist_id: 453,
    city_id: 34,
    district_name: "Sile",
    status: 1,
  },
  {
    dist_id: 454,
    city_id: 34,
    district_name: "Sultanbeyli",
    status: 1,
  },
  {
    dist_id: 455,
    city_id: 35,
    district_name: "Aliaga",
    status: 1,
  },
  {
    dist_id: 456,
    city_id: 35,
    district_name: "Balçova",
    status: 1,
  },
  {
    dist_id: 457,
    city_id: 35,
    district_name: "Bayındır",
    status: 1,
  },
  {
    dist_id: 458,
    city_id: 35,
    district_name: "Bornova",
    status: 1,
  },
  {
    dist_id: 459,
    city_id: 35,
    district_name: "Buca",
    status: 1,
  },
  {
    dist_id: 460,
    city_id: 35,
    district_name: "Çigli",
    status: 1,
  },
  {
    dist_id: 461,
    city_id: 35,
    district_name: "Foça",
    status: 1,
  },
  {
    dist_id: 462,
    city_id: 35,
    district_name: "Gaziemir",
    status: 1,
  },
  {
    dist_id: 463,
    city_id: 35,
    district_name: "Güzelbahçe",
    status: 1,
  },
  {
    dist_id: 464,
    city_id: 35,
    district_name: "Karsıyaka",
    status: 1,
  },
  {
    dist_id: 465,
    city_id: 35,
    district_name: "Kemalpasa",
    status: 1,
  },
  {
    dist_id: 466,
    city_id: 35,
    district_name: "Konak",
    status: 1,
  },
  {
    dist_id: 467,
    city_id: 35,
    district_name: "Cumaovası(Menderes)",
    status: 1,
  },
  {
    dist_id: 468,
    city_id: 35,
    district_name: "Menemen",
    status: 1,
  },
  {
    dist_id: 469,
    city_id: 35,
    district_name: "Narlıdere",
    status: 1,
  },
  {
    dist_id: 470,
    city_id: 35,
    district_name: "Seferihisar",
    status: 1,
  },
  {
    dist_id: 471,
    city_id: 35,
    district_name: "Selçuk",
    status: 1,
  },
  {
    dist_id: 472,
    city_id: 35,
    district_name: "Torbalı",
    status: 1,
  },
  {
    dist_id: 473,
    city_id: 35,
    district_name: "Urla",
    status: 1,
  },
  {
    dist_id: 474,
    city_id: 35,
    district_name: "Bayraklı",
    status: 1,
  },
  {
    dist_id: 475,
    city_id: 35,
    district_name: "Karabaglar",
    status: 1,
  },
  {
    dist_id: 476,
    city_id: 35,
    district_name: "Bergama",
    status: 1,
  },
  {
    dist_id: 477,
    city_id: 35,
    district_name: "Beydag",
    status: 1,
  },
  {
    dist_id: 478,
    city_id: 35,
    district_name: "Çesme",
    status: 1,
  },
  {
    dist_id: 479,
    city_id: 35,
    district_name: "Dikili",
    status: 1,
  },
  {
    dist_id: 480,
    city_id: 35,
    district_name: "Karaburun",
    status: 1,
  },
  {
    dist_id: 481,
    city_id: 35,
    district_name: "Kınık",
    status: 1,
  },
  {
    dist_id: 482,
    city_id: 35,
    district_name: "Kiraz",
    status: 1,
  },
  {
    dist_id: 483,
    city_id: 35,
    district_name: "Ödemis",
    status: 1,
  },
  {
    dist_id: 484,
    city_id: 35,
    district_name: "Tire",
    status: 1,
  },
  {
    dist_id: 485,
    city_id: 36,
    district_name: "Kars",
    status: 1,
  },
  {
    dist_id: 486,
    city_id: 36,
    district_name: "Akyaka",
    status: 1,
  },
  {
    dist_id: 487,
    city_id: 36,
    district_name: "Arpaçay",
    status: 1,
  },
  {
    dist_id: 488,
    city_id: 36,
    district_name: "Digor",
    status: 1,
  },
  {
    dist_id: 489,
    city_id: 36,
    district_name: "Kagızman",
    status: 1,
  },
  {
    dist_id: 490,
    city_id: 36,
    district_name: "Sarıkamıs",
    status: 1,
  },
  {
    dist_id: 491,
    city_id: 36,
    district_name: "Selim",
    status: 1,
  },
  {
    dist_id: 492,
    city_id: 36,
    district_name: "Susuz",
    status: 1,
  },
  {
    dist_id: 493,
    city_id: 37,
    district_name: "Kastamonu",
    status: 1,
  },
  {
    dist_id: 494,
    city_id: 37,
    district_name: "Abana",
    status: 1,
  },
  {
    dist_id: 495,
    city_id: 37,
    district_name: "Aglı",
    status: 1,
  },
  {
    dist_id: 496,
    city_id: 37,
    district_name: "Araç",
    status: 1,
  },
  {
    dist_id: 497,
    city_id: 37,
    district_name: "Azdavay",
    status: 1,
  },
  {
    dist_id: 498,
    city_id: 37,
    district_name: "Bozkurt",
    status: 1,
  },
  {
    dist_id: 499,
    city_id: 37,
    district_name: "Cide",
    status: 1,
  },
  {
    dist_id: 500,
    city_id: 37,
    district_name: "Çatalzeytin",
    status: 1,
  },
  {
    dist_id: 501,
    city_id: 37,
    district_name: "Daday",
    status: 1,
  },
  {
    dist_id: 502,
    city_id: 37,
    district_name: "Devrekani",
    status: 1,
  },
  {
    dist_id: 503,
    city_id: 37,
    district_name: "Doganyurt",
    status: 1,
  },
  {
    dist_id: 504,
    city_id: 37,
    district_name: "Hanönü(Gökçeagaç)",
    status: 1,
  },
  {
    dist_id: 505,
    city_id: 37,
    district_name: "Ihsangazi",
    status: 1,
  },
  {
    dist_id: 506,
    city_id: 37,
    district_name: "Inebolu",
    status: 1,
  },
  {
    dist_id: 507,
    city_id: 37,
    district_name: "Küre",
    status: 1,
  },
  {
    dist_id: 508,
    city_id: 37,
    district_name: "Pınarbası",
    status: 1,
  },
  {
    dist_id: 509,
    city_id: 37,
    district_name: "Seydiler",
    status: 1,
  },
  {
    dist_id: 510,
    city_id: 37,
    district_name: "Senpazar",
    status: 1,
  },
  {
    dist_id: 511,
    city_id: 37,
    district_name: "Tasköprü",
    status: 1,
  },
  {
    dist_id: 512,
    city_id: 37,
    district_name: "Tosya",
    status: 1,
  },
  {
    dist_id: 513,
    city_id: 38,
    district_name: "Kocasinan",
    status: 1,
  },
  {
    dist_id: 514,
    city_id: 38,
    district_name: "Melikgazi",
    status: 1,
  },
  {
    dist_id: 515,
    city_id: 38,
    district_name: "Talas",
    status: 1,
  },
  {
    dist_id: 516,
    city_id: 38,
    district_name: "Akkısla",
    status: 1,
  },
  {
    dist_id: 517,
    city_id: 38,
    district_name: "Bünyan",
    status: 1,
  },
  {
    dist_id: 518,
    city_id: 38,
    district_name: "Develi",
    status: 1,
  },
  {
    dist_id: 519,
    city_id: 38,
    district_name: "Felahiye",
    status: 1,
  },
  {
    dist_id: 520,
    city_id: 38,
    district_name: "Hacılar",
    status: 1,
  },
  {
    dist_id: 521,
    city_id: 38,
    district_name: "Incesu",
    status: 1,
  },
  {
    dist_id: 522,
    city_id: 38,
    district_name: "Özvatan(Çukur)",
    status: 1,
  },
  {
    dist_id: 523,
    city_id: 38,
    district_name: "Pınarbası",
    status: 1,
  },
  {
    dist_id: 524,
    city_id: 38,
    district_name: "Sarıoglan",
    status: 1,
  },
  {
    dist_id: 525,
    city_id: 38,
    district_name: "Sarız",
    status: 1,
  },
  {
    dist_id: 526,
    city_id: 38,
    district_name: "Tomarza",
    status: 1,
  },
  {
    dist_id: 527,
    city_id: 38,
    district_name: "Yahyalı",
    status: 1,
  },
  {
    dist_id: 528,
    city_id: 38,
    district_name: "Yesilhisar",
    status: 1,
  },
  {
    dist_id: 529,
    city_id: 39,
    district_name: "Kırklareli",
    status: 1,
  },
  {
    dist_id: 530,
    city_id: 39,
    district_name: "Babaeski",
    status: 1,
  },
  {
    dist_id: 531,
    city_id: 39,
    district_name: "Demirköy",
    status: 1,
  },
  {
    dist_id: 532,
    city_id: 39,
    district_name: "Kofçaz",
    status: 1,
  },
  {
    dist_id: 533,
    city_id: 39,
    district_name: "Lüleburgaz",
    status: 1,
  },
  {
    dist_id: 534,
    city_id: 39,
    district_name: "Pehlivanköy",
    status: 1,
  },
  {
    dist_id: 535,
    city_id: 39,
    district_name: "Pınarhisar",
    status: 1,
  },
  {
    dist_id: 536,
    city_id: 39,
    district_name: "Vize",
    status: 1,
  },
  {
    dist_id: 537,
    city_id: 40,
    district_name: "Kırsehir",
    status: 1,
  },
  {
    dist_id: 538,
    city_id: 40,
    district_name: "Akçakent",
    status: 1,
  },
  {
    dist_id: 539,
    city_id: 40,
    district_name: "Akpınar",
    status: 1,
  },
  {
    dist_id: 540,
    city_id: 40,
    district_name: "Boztepe",
    status: 1,
  },
  {
    dist_id: 541,
    city_id: 40,
    district_name: "Çiçekdagı",
    status: 1,
  },
  {
    dist_id: 542,
    city_id: 40,
    district_name: "Kaman",
    status: 1,
  },
  {
    dist_id: 543,
    city_id: 40,
    district_name: "Mucur",
    status: 1,
  },
  {
    dist_id: 544,
    city_id: 41,
    district_name: "Izmit",
    status: 1,
  },
  {
    dist_id: 545,
    city_id: 41,
    district_name: "Basiskele",
    status: 1,
  },
  {
    dist_id: 546,
    city_id: 41,
    district_name: "Çayırova",
    status: 1,
  },
  {
    dist_id: 547,
    city_id: 41,
    district_name: "Darıca",
    status: 1,
  },
  {
    dist_id: 548,
    city_id: 41,
    district_name: "Dilovası",
    status: 1,
  },
  {
    dist_id: 549,
    city_id: 41,
    district_name: "Kartepe",
    status: 1,
  },
  {
    dist_id: 550,
    city_id: 41,
    district_name: "Gebze",
    status: 1,
  },
  {
    dist_id: 551,
    city_id: 41,
    district_name: "Gölcük",
    status: 1,
  },
  {
    dist_id: 552,
    city_id: 41,
    district_name: "Kandıra",
    status: 1,
  },
  {
    dist_id: 553,
    city_id: 41,
    district_name: "Karamürsel",
    status: 1,
  },
  {
    dist_id: 554,
    city_id: 41,
    district_name: "Tütünçiftlik",
    status: 1,
  },
  {
    dist_id: 555,
    city_id: 41,
    district_name: "Derince",
    status: 1,
  },
  {
    dist_id: 556,
    city_id: 42,
    district_name: "Karatay",
    status: 1,
  },
  {
    dist_id: 557,
    city_id: 42,
    district_name: "Meram",
    status: 1,
  },
  {
    dist_id: 558,
    city_id: 42,
    district_name: "Selçuklu",
    status: 1,
  },
  {
    dist_id: 559,
    city_id: 42,
    district_name: "Ahırlı",
    status: 1,
  },
  {
    dist_id: 560,
    city_id: 42,
    district_name: "Akören",
    status: 1,
  },
  {
    dist_id: 561,
    city_id: 42,
    district_name: "Aksehir",
    status: 1,
  },
  {
    dist_id: 562,
    city_id: 42,
    district_name: "Altınekin",
    status: 1,
  },
  {
    dist_id: 563,
    city_id: 42,
    district_name: "Beysehir",
    status: 1,
  },
  {
    dist_id: 564,
    city_id: 42,
    district_name: "Bozkır",
    status: 1,
  },
  {
    dist_id: 565,
    city_id: 42,
    district_name: "Cihanbeyli",
    status: 1,
  },
  {
    dist_id: 566,
    city_id: 42,
    district_name: "Çeltik",
    status: 1,
  },
  {
    dist_id: 567,
    city_id: 42,
    district_name: "Çumra",
    status: 1,
  },
  {
    dist_id: 568,
    city_id: 42,
    district_name: "Derbent",
    status: 1,
  },
  {
    dist_id: 569,
    city_id: 42,
    district_name: "Derebucak",
    status: 1,
  },
  {
    dist_id: 570,
    city_id: 42,
    district_name: "Doganhisar",
    status: 1,
  },
  {
    dist_id: 571,
    city_id: 42,
    district_name: "Emirgazi",
    status: 1,
  },
  {
    dist_id: 572,
    city_id: 42,
    district_name: "Eregli",
    status: 1,
  },
  {
    dist_id: 573,
    city_id: 42,
    district_name: "Güneysınır",
    status: 1,
  },
  {
    dist_id: 574,
    city_id: 42,
    district_name: "Hadim",
    status: 1,
  },
  {
    dist_id: 575,
    city_id: 42,
    district_name: "Halkapınar",
    status: 1,
  },
  {
    dist_id: 576,
    city_id: 42,
    district_name: "Hüyük",
    status: 1,
  },
  {
    dist_id: 577,
    city_id: 42,
    district_name: "Ilgın",
    status: 1,
  },
  {
    dist_id: 578,
    city_id: 42,
    district_name: "Kadınhanı",
    status: 1,
  },
  {
    dist_id: 579,
    city_id: 42,
    district_name: "Karapınar",
    status: 1,
  },
  {
    dist_id: 580,
    city_id: 42,
    district_name: "Kulu",
    status: 1,
  },
  {
    dist_id: 581,
    city_id: 42,
    district_name: "Sarayönü",
    status: 1,
  },
  {
    dist_id: 582,
    city_id: 42,
    district_name: "Seydisehir",
    status: 1,
  },
  {
    dist_id: 583,
    city_id: 42,
    district_name: "Taskent",
    status: 1,
  },
  {
    dist_id: 584,
    city_id: 42,
    district_name: "Tuzlukçu",
    status: 1,
  },
  {
    dist_id: 585,
    city_id: 42,
    district_name: "Yalıhüyük",
    status: 1,
  },
  {
    dist_id: 586,
    city_id: 42,
    district_name: "Yunak",
    status: 1,
  },
  {
    dist_id: 587,
    city_id: 43,
    district_name: "Kütahya",
    status: 1,
  },
  {
    dist_id: 588,
    city_id: 43,
    district_name: "Altıntas",
    status: 1,
  },
  {
    dist_id: 589,
    city_id: 43,
    district_name: "Aslanapa",
    status: 1,
  },
  {
    dist_id: 590,
    city_id: 43,
    district_name: "Çavdarhisar",
    status: 1,
  },
  {
    dist_id: 591,
    city_id: 43,
    district_name: "Domaniç",
    status: 1,
  },
  {
    dist_id: 592,
    city_id: 43,
    district_name: "Dumlupınar",
    status: 1,
  },
  {
    dist_id: 593,
    city_id: 43,
    district_name: "Emet",
    status: 1,
  },
  {
    dist_id: 594,
    city_id: 43,
    district_name: "Gediz",
    status: 1,
  },
  {
    dist_id: 595,
    city_id: 43,
    district_name: "Hisarcık",
    status: 1,
  },
  {
    dist_id: 596,
    city_id: 43,
    district_name: "Pazarlar",
    status: 1,
  },
  {
    dist_id: 597,
    city_id: 43,
    district_name: "Simav",
    status: 1,
  },
  {
    dist_id: 598,
    city_id: 43,
    district_name: "Saphane",
    status: 1,
  },
  {
    dist_id: 599,
    city_id: 43,
    district_name: "Tavsanlı",
    status: 1,
  },
  {
    dist_id: 600,
    city_id: 43,
    district_name: "Tunçbilek",
    status: 1,
  },
  {
    dist_id: 601,
    city_id: 44,
    district_name: "Malatya",
    status: 1,
  },
  {
    dist_id: 602,
    city_id: 44,
    district_name: "Akçadag",
    status: 1,
  },
  {
    dist_id: 603,
    city_id: 44,
    district_name: "Arapkir",
    status: 1,
  },
  {
    dist_id: 604,
    city_id: 44,
    district_name: "Arguvan",
    status: 1,
  },
  {
    dist_id: 605,
    city_id: 44,
    district_name: "Battalgazi",
    status: 1,
  },
  {
    dist_id: 606,
    city_id: 44,
    district_name: "Darende",
    status: 1,
  },
  {
    dist_id: 607,
    city_id: 44,
    district_name: "Dogansehir",
    status: 1,
  },
  {
    dist_id: 608,
    city_id: 44,
    district_name: "Doganyol",
    status: 1,
  },
  {
    dist_id: 609,
    city_id: 44,
    district_name: "Hekimhan",
    status: 1,
  },
  {
    dist_id: 610,
    city_id: 44,
    district_name: "Kale",
    status: 1,
  },
  {
    dist_id: 611,
    city_id: 44,
    district_name: "Kuluncak",
    status: 1,
  },
  {
    dist_id: 612,
    city_id: 44,
    district_name: "Pötürge",
    status: 1,
  },
  {
    dist_id: 613,
    city_id: 44,
    district_name: "Yazıhan",
    status: 1,
  },
  {
    dist_id: 614,
    city_id: 44,
    district_name: "Yesilyurt",
    status: 1,
  },
  {
    dist_id: 615,
    city_id: 45,
    district_name: "Manisa",
    status: 1,
  },
  {
    dist_id: 616,
    city_id: 45,
    district_name: "Ahmetli",
    status: 1,
  },
  {
    dist_id: 617,
    city_id: 45,
    district_name: "Akhisar",
    status: 1,
  },
  {
    dist_id: 618,
    city_id: 45,
    district_name: "Alasehir",
    status: 1,
  },
  {
    dist_id: 619,
    city_id: 45,
    district_name: "Demirci",
    status: 1,
  },
  {
    dist_id: 620,
    city_id: 45,
    district_name: "Gölmarmara",
    status: 1,
  },
  {
    dist_id: 621,
    city_id: 45,
    district_name: "Gördes",
    status: 1,
  },
  {
    dist_id: 622,
    city_id: 45,
    district_name: "Kırkagaç",
    status: 1,
  },
  {
    dist_id: 623,
    city_id: 45,
    district_name: "Köprübası",
    status: 1,
  },
  {
    dist_id: 624,
    city_id: 45,
    district_name: "Kula",
    status: 1,
  },
  {
    dist_id: 625,
    city_id: 45,
    district_name: "Salihli",
    status: 1,
  },
  {
    dist_id: 626,
    city_id: 45,
    district_name: "Sarıgöl",
    status: 1,
  },
  {
    dist_id: 627,
    city_id: 45,
    district_name: "Saruhanlı",
    status: 1,
  },
  {
    dist_id: 628,
    city_id: 45,
    district_name: "Selendi",
    status: 1,
  },
  {
    dist_id: 629,
    city_id: 45,
    district_name: "Soma",
    status: 1,
  },
  {
    dist_id: 630,
    city_id: 45,
    district_name: "Turgutlu",
    status: 1,
  },
  {
    dist_id: 631,
    city_id: 46,
    district_name: "Kahramanmaras",
    status: 1,
  },
  {
    dist_id: 632,
    city_id: 46,
    district_name: "Afsin",
    status: 1,
  },
  {
    dist_id: 633,
    city_id: 46,
    district_name: "Andırın",
    status: 1,
  },
  {
    dist_id: 634,
    city_id: 46,
    district_name: "Çaglayancerit",
    status: 1,
  },
  {
    dist_id: 635,
    city_id: 46,
    district_name: "Ekinözü",
    status: 1,
  },
  {
    dist_id: 636,
    city_id: 46,
    district_name: "Elbistan",
    status: 1,
  },
  {
    dist_id: 637,
    city_id: 46,
    district_name: "Göksun",
    status: 1,
  },
  {
    dist_id: 638,
    city_id: 46,
    district_name: "Nurhak",
    status: 1,
  },
  {
    dist_id: 639,
    city_id: 46,
    district_name: "Pazarcık",
    status: 1,
  },
  {
    dist_id: 640,
    city_id: 46,
    district_name: "Türkoglu",
    status: 1,
  },
  {
    dist_id: 641,
    city_id: 47,
    district_name: "Mardin",
    status: 1,
  },
  {
    dist_id: 642,
    city_id: 47,
    district_name: "Dargeçit",
    status: 1,
  },
  {
    dist_id: 643,
    city_id: 47,
    district_name: "Derik",
    status: 1,
  },
  {
    dist_id: 644,
    city_id: 47,
    district_name: "Kızıltepe",
    status: 1,
  },
  {
    dist_id: 645,
    city_id: 47,
    district_name: "Mazıdagı",
    status: 1,
  },
  {
    dist_id: 646,
    city_id: 47,
    district_name: "Midyat(Estel)",
    status: 1,
  },
  {
    dist_id: 647,
    city_id: 47,
    district_name: "Nusaybin",
    status: 1,
  },
  {
    dist_id: 648,
    city_id: 47,
    district_name: "Ömerli",
    status: 1,
  },
  {
    dist_id: 649,
    city_id: 47,
    district_name: "Savur",
    status: 1,
  },
  {
    dist_id: 650,
    city_id: 47,
    district_name: "Yesilli",
    status: 1,
  },
  {
    dist_id: 651,
    city_id: 48,
    district_name: "Mugla",
    status: 1,
  },
  {
    dist_id: 652,
    city_id: 48,
    district_name: "Bodrum",
    status: 1,
  },
  {
    dist_id: 653,
    city_id: 48,
    district_name: "Dalaman",
    status: 1,
  },
  {
    dist_id: 654,
    city_id: 48,
    district_name: "Datça",
    status: 1,
  },
  {
    dist_id: 655,
    city_id: 48,
    district_name: "Fethiye",
    status: 1,
  },
  {
    dist_id: 656,
    city_id: 48,
    district_name: "Kavaklıdere",
    status: 1,
  },
  {
    dist_id: 657,
    city_id: 48,
    district_name: "Köycegiz",
    status: 1,
  },
  {
    dist_id: 658,
    city_id: 48,
    district_name: "Marmaris",
    status: 1,
  },
  {
    dist_id: 659,
    city_id: 48,
    district_name: "Milas",
    status: 1,
  },
  {
    dist_id: 660,
    city_id: 48,
    district_name: "Ortaca",
    status: 1,
  },
  {
    dist_id: 661,
    city_id: 48,
    district_name: "Ulaula",
    status: 1,
  },
  {
    dist_id: 662,
    city_id: 48,
    district_name: "Yatagan",
    status: 1,
  },
  {
    dist_id: 663,
    city_id: 49,
    district_name: "Mus",
    status: 1,
  },
  {
    dist_id: 664,
    city_id: 49,
    district_name: "Bulanık",
    status: 1,
  },
  {
    dist_id: 665,
    city_id: 49,
    district_name: "Hasköy",
    status: 1,
  },
  {
    dist_id: 666,
    city_id: 49,
    district_name: "Korkut",
    status: 1,
  },
  {
    dist_id: 667,
    city_id: 49,
    district_name: "Malazgirt",
    status: 1,
  },
  {
    dist_id: 668,
    city_id: 49,
    district_name: "Varto",
    status: 1,
  },
  {
    dist_id: 669,
    city_id: 50,
    district_name: "Nevsehir",
    status: 1,
  },
  {
    dist_id: 670,
    city_id: 50,
    district_name: "Acıgöl",
    status: 1,
  },
  {
    dist_id: 671,
    city_id: 50,
    district_name: "Avanos",
    status: 1,
  },
  {
    dist_id: 672,
    city_id: 50,
    district_name: "Derinkuyu",
    status: 1,
  },
  {
    dist_id: 673,
    city_id: 50,
    district_name: "Gülsehir",
    status: 1,
  },
  {
    dist_id: 674,
    city_id: 50,
    district_name: "Hacıbektas",
    status: 1,
  },
  {
    dist_id: 675,
    city_id: 50,
    district_name: "Kozaklı",
    status: 1,
  },
  {
    dist_id: 676,
    city_id: 50,
    district_name: "Göreme",
    status: 1,
  },
  {
    dist_id: 677,
    city_id: 51,
    district_name: "Nigde",
    status: 1,
  },
  {
    dist_id: 678,
    city_id: 51,
    district_name: "Altunhisar",
    status: 1,
  },
  {
    dist_id: 679,
    city_id: 51,
    district_name: "Bor",
    status: 1,
  },
  {
    dist_id: 680,
    city_id: 51,
    district_name: "Çamardı",
    status: 1,
  },
  {
    dist_id: 681,
    city_id: 51,
    district_name: "Çiftlik(Özyurt)",
    status: 1,
  },
  {
    dist_id: 682,
    city_id: 51,
    district_name: "Ulukısla",
    status: 1,
  },
  {
    dist_id: 683,
    city_id: 52,
    district_name: "Ordu",
    status: 1,
  },
  {
    dist_id: 684,
    city_id: 52,
    district_name: "Akkus",
    status: 1,
  },
  {
    dist_id: 685,
    city_id: 52,
    district_name: "Aybastı",
    status: 1,
  },
  {
    dist_id: 686,
    city_id: 52,
    district_name: "Çamas",
    status: 1,
  },
  {
    dist_id: 687,
    city_id: 52,
    district_name: "Çatalpınar",
    status: 1,
  },
  {
    dist_id: 688,
    city_id: 52,
    district_name: "Çaybası",
    status: 1,
  },
  {
    dist_id: 689,
    city_id: 52,
    district_name: "Fatsa",
    status: 1,
  },
  {
    dist_id: 690,
    city_id: 52,
    district_name: "Gölköy",
    status: 1,
  },
  {
    dist_id: 691,
    city_id: 52,
    district_name: "Gülyalı",
    status: 1,
  },
  {
    dist_id: 692,
    city_id: 52,
    district_name: "Gürgentepe",
    status: 1,
  },
  {
    dist_id: 693,
    city_id: 52,
    district_name: "Ikizce",
    status: 1,
  },
  {
    dist_id: 694,
    city_id: 52,
    district_name: "Karadüz(Kabadüz)",
    status: 1,
  },
  {
    dist_id: 695,
    city_id: 52,
    district_name: "Kabatas",
    status: 1,
  },
  {
    dist_id: 696,
    city_id: 52,
    district_name: "Korgan",
    status: 1,
  },
  {
    dist_id: 697,
    city_id: 52,
    district_name: "Kumru",
    status: 1,
  },
  {
    dist_id: 698,
    city_id: 52,
    district_name: "Mesudiye",
    status: 1,
  },
  {
    dist_id: 699,
    city_id: 52,
    district_name: "Persembe",
    status: 1,
  },
  {
    dist_id: 700,
    city_id: 52,
    district_name: "Ulubey",
    status: 1,
  },
  {
    dist_id: 701,
    city_id: 52,
    district_name: "Ünye",
    status: 1,
  },
  {
    dist_id: 702,
    city_id: 53,
    district_name: "Rize",
    status: 1,
  },
  {
    dist_id: 703,
    city_id: 53,
    district_name: "Ardesen",
    status: 1,
  },
  {
    dist_id: 704,
    city_id: 53,
    district_name: "Çamlıhemsin",
    status: 1,
  },
  {
    dist_id: 705,
    city_id: 53,
    district_name: "Çayeli",
    status: 1,
  },
  {
    dist_id: 706,
    city_id: 53,
    district_name: "Derepazarı",
    status: 1,
  },
  {
    dist_id: 707,
    city_id: 53,
    district_name: "Fındıklı",
    status: 1,
  },
  {
    dist_id: 708,
    city_id: 53,
    district_name: "Güneysu",
    status: 1,
  },
  {
    dist_id: 709,
    city_id: 53,
    district_name: "Hemsin",
    status: 1,
  },
  {
    dist_id: 710,
    city_id: 53,
    district_name: "Ikizdere",
    status: 1,
  },
  {
    dist_id: 711,
    city_id: 53,
    district_name: "Iyidere",
    status: 1,
  },
  {
    dist_id: 712,
    city_id: 53,
    district_name: "Kalkandere",
    status: 1,
  },
  {
    dist_id: 713,
    city_id: 53,
    district_name: "Pazar",
    status: 1,
  },
  {
    dist_id: 714,
    city_id: 54,
    district_name: "Adapazarı",
    status: 1,
  },
  {
    dist_id: 715,
    city_id: 54,
    district_name: "Hendek",
    status: 1,
  },
  {
    dist_id: 716,
    city_id: 54,
    district_name: "Arifiye",
    status: 1,
  },
  {
    dist_id: 717,
    city_id: 54,
    district_name: "Erenler",
    status: 1,
  },
  {
    dist_id: 718,
    city_id: 54,
    district_name: "Serdivan",
    status: 1,
  },
  {
    dist_id: 719,
    city_id: 54,
    district_name: "Akyazı",
    status: 1,
  },
  {
    dist_id: 720,
    city_id: 54,
    district_name: "Ferizli",
    status: 1,
  },
  {
    dist_id: 721,
    city_id: 54,
    district_name: "Geyve",
    status: 1,
  },
  {
    dist_id: 722,
    city_id: 54,
    district_name: "Karapürçek",
    status: 1,
  },
  {
    dist_id: 723,
    city_id: 54,
    district_name: "Karasu",
    status: 1,
  },
  {
    dist_id: 724,
    city_id: 54,
    district_name: "Kaynarca",
    status: 1,
  },
  {
    dist_id: 725,
    city_id: 54,
    district_name: "Kocaali",
    status: 1,
  },
  {
    dist_id: 726,
    city_id: 54,
    district_name: "Pamukova",
    status: 1,
  },
  {
    dist_id: 727,
    city_id: 54,
    district_name: "Sapanca",
    status: 1,
  },
  {
    dist_id: 728,
    city_id: 54,
    district_name: "Sögütlü",
    status: 1,
  },
  {
    dist_id: 729,
    city_id: 54,
    district_name: "Taraklı",
    status: 1,
  },
  {
    dist_id: 730,
    city_id: 55,
    district_name: "Atakum",
    status: 1,
  },
  {
    dist_id: 731,
    city_id: 55,
    district_name: "Ilkadım",
    status: 1,
  },
  {
    dist_id: 732,
    city_id: 55,
    district_name: "Canik",
    status: 1,
  },
  {
    dist_id: 733,
    city_id: 55,
    district_name: "Tekkeköy",
    status: 1,
  },
  {
    dist_id: 734,
    city_id: 55,
    district_name: "Alaçam",
    status: 1,
  },
  {
    dist_id: 735,
    city_id: 55,
    district_name: "Asarcık",
    status: 1,
  },
  {
    dist_id: 736,
    city_id: 55,
    district_name: "Ayvacık",
    status: 1,
  },
  {
    dist_id: 737,
    city_id: 55,
    district_name: "Bafra",
    status: 1,
  },
  {
    dist_id: 738,
    city_id: 55,
    district_name: "Çarsamba",
    status: 1,
  },
  {
    dist_id: 739,
    city_id: 55,
    district_name: "Havza",
    status: 1,
  },
  {
    dist_id: 740,
    city_id: 55,
    district_name: "Kavak",
    status: 1,
  },
  {
    dist_id: 741,
    city_id: 55,
    district_name: "Ladik",
    status: 1,
  },
  {
    dist_id: 742,
    city_id: 55,
    district_name: "19Mayıs(Ballıca)",
    status: 1,
  },
  {
    dist_id: 743,
    city_id: 55,
    district_name: "Salıpazarı",
    status: 1,
  },
  {
    dist_id: 744,
    city_id: 55,
    district_name: "Terme",
    status: 1,
  },
  {
    dist_id: 745,
    city_id: 55,
    district_name: "Vezirköprü",
    status: 1,
  },
  {
    dist_id: 746,
    city_id: 55,
    district_name: "Yakakent",
    status: 1,
  },
  {
    dist_id: 747,
    city_id: 56,
    district_name: "Siirt",
    status: 1,
  },
  {
    dist_id: 748,
    city_id: 56,
    district_name: "Baykan",
    status: 1,
  },
  {
    dist_id: 749,
    city_id: 56,
    district_name: "Eruh",
    status: 1,
  },
  {
    dist_id: 750,
    city_id: 56,
    district_name: "Kurtalan",
    status: 1,
  },
  {
    dist_id: 751,
    city_id: 56,
    district_name: "Pervari",
    status: 1,
  },
  {
    dist_id: 752,
    city_id: 56,
    district_name: "Aydınlar",
    status: 1,
  },
  {
    dist_id: 753,
    city_id: 56,
    district_name: "Sirvan",
    status: 1,
  },
  {
    dist_id: 754,
    city_id: 57,
    district_name: "Sinop",
    status: 1,
  },
  {
    dist_id: 755,
    city_id: 57,
    district_name: "Ayancık",
    status: 1,
  },
  {
    dist_id: 756,
    city_id: 57,
    district_name: "Boyabat",
    status: 1,
  },
  {
    dist_id: 757,
    city_id: 57,
    district_name: "Dikmen",
    status: 1,
  },
  {
    dist_id: 758,
    city_id: 57,
    district_name: "Duragan",
    status: 1,
  },
  {
    dist_id: 759,
    city_id: 57,
    district_name: "Erfelek",
    status: 1,
  },
  {
    dist_id: 760,
    city_id: 57,
    district_name: "Gerze",
    status: 1,
  },
  {
    dist_id: 761,
    city_id: 57,
    district_name: "Saraydüzü",
    status: 1,
  },
  {
    dist_id: 762,
    city_id: 57,
    district_name: "Türkeli",
    status: 1,
  },
  {
    dist_id: 763,
    city_id: 58,
    district_name: "Sivas",
    status: 1,
  },
  {
    dist_id: 764,
    city_id: 58,
    district_name: "Akıncılar",
    status: 1,
  },
  {
    dist_id: 765,
    city_id: 58,
    district_name: "Altınyayla",
    status: 1,
  },
  {
    dist_id: 766,
    city_id: 58,
    district_name: "Divrigi",
    status: 1,
  },
  {
    dist_id: 767,
    city_id: 58,
    district_name: "Dogansar",
    status: 1,
  },
  {
    dist_id: 768,
    city_id: 58,
    district_name: "Gemerek",
    status: 1,
  },
  {
    dist_id: 769,
    city_id: 58,
    district_name: "Gölova",
    status: 1,
  },
  {
    dist_id: 770,
    city_id: 58,
    district_name: "Gürün",
    status: 1,
  },
  {
    dist_id: 771,
    city_id: 58,
    district_name: "Hafik",
    status: 1,
  },
  {
    dist_id: 772,
    city_id: 58,
    district_name: "Imranlı",
    status: 1,
  },
  {
    dist_id: 773,
    city_id: 58,
    district_name: "Kangal",
    status: 1,
  },
  {
    dist_id: 774,
    city_id: 58,
    district_name: "Koyulhisar",
    status: 1,
  },
  {
    dist_id: 775,
    city_id: 58,
    district_name: "Susehri",
    status: 1,
  },
  {
    dist_id: 776,
    city_id: 58,
    district_name: "Sarkısla",
    status: 1,
  },
  {
    dist_id: 777,
    city_id: 58,
    district_name: "Ulas",
    status: 1,
  },
  {
    dist_id: 778,
    city_id: 58,
    district_name: "Yıldızeli",
    status: 1,
  },
  {
    dist_id: 779,
    city_id: 58,
    district_name: "Zara",
    status: 1,
  },
  {
    dist_id: 780,
    city_id: 59,
    district_name: "Tekirdag",
    status: 1,
  },
  {
    dist_id: 781,
    city_id: 59,
    district_name: "Çerkezköy",
    status: 1,
  },
  {
    dist_id: 782,
    city_id: 59,
    district_name: "Çorlu",
    status: 1,
  },
  {
    dist_id: 783,
    city_id: 59,
    district_name: "Hayrabolu",
    status: 1,
  },
  {
    dist_id: 784,
    city_id: 59,
    district_name: "Malkara",
    status: 1,
  },
  {
    dist_id: 785,
    city_id: 59,
    district_name: "Marmaraereglisi",
    status: 1,
  },
  {
    dist_id: 786,
    city_id: 59,
    district_name: "Muratlı",
    status: 1,
  },
  {
    dist_id: 787,
    city_id: 59,
    district_name: "Saray",
    status: 1,
  },
  {
    dist_id: 788,
    city_id: 59,
    district_name: "Sarköy",
    status: 1,
  },
  {
    dist_id: 789,
    city_id: 60,
    district_name: "Tokat",
    status: 1,
  },
  {
    dist_id: 790,
    city_id: 60,
    district_name: "Almus",
    status: 1,
  },
  {
    dist_id: 791,
    city_id: 60,
    district_name: "Artova",
    status: 1,
  },
  {
    dist_id: 792,
    city_id: 60,
    district_name: "Basçiftlik",
    status: 1,
  },
  {
    dist_id: 793,
    city_id: 60,
    district_name: "Erbaa",
    status: 1,
  },
  {
    dist_id: 794,
    city_id: 60,
    district_name: "Niksar",
    status: 1,
  },
  {
    dist_id: 795,
    city_id: 60,
    district_name: "Pazar",
    status: 1,
  },
  {
    dist_id: 796,
    city_id: 60,
    district_name: "Resadiye",
    status: 1,
  },
  {
    dist_id: 797,
    city_id: 60,
    district_name: "Sulusaray",
    status: 1,
  },
  {
    dist_id: 798,
    city_id: 60,
    district_name: "Turhal",
    status: 1,
  },
  {
    dist_id: 799,
    city_id: 60,
    district_name: "Yesilyurt",
    status: 1,
  },
  {
    dist_id: 800,
    city_id: 60,
    district_name: "Zile",
    status: 1,
  },
  {
    dist_id: 801,
    city_id: 61,
    district_name: "Trabzon",
    status: 1,
  },
  {
    dist_id: 802,
    city_id: 61,
    district_name: "Akçaabat",
    status: 1,
  },
  {
    dist_id: 803,
    city_id: 61,
    district_name: "Araklı",
    status: 1,
  },
  {
    dist_id: 804,
    city_id: 61,
    district_name: "Arsin",
    status: 1,
  },
  {
    dist_id: 805,
    city_id: 61,
    district_name: "Besikdüzü",
    status: 1,
  },
  {
    dist_id: 806,
    city_id: 61,
    district_name: "Çarsıbası",
    status: 1,
  },
  {
    dist_id: 807,
    city_id: 61,
    district_name: "Çaykara",
    status: 1,
  },
  {
    dist_id: 808,
    city_id: 61,
    district_name: "Dernekpazarı",
    status: 1,
  },
  {
    dist_id: 809,
    city_id: 61,
    district_name: "Düzköy",
    status: 1,
  },
  {
    dist_id: 810,
    city_id: 61,
    district_name: "Hayrat",
    status: 1,
  },
  {
    dist_id: 811,
    city_id: 61,
    district_name: "Köprübası",
    status: 1,
  },
  {
    dist_id: 812,
    city_id: 61,
    district_name: "Maçka",
    status: 1,
  },
  {
    dist_id: 813,
    city_id: 61,
    district_name: "Of",
    status: 1,
  },
  {
    dist_id: 814,
    city_id: 61,
    district_name: "Sürmene",
    status: 1,
  },
  {
    dist_id: 815,
    city_id: 61,
    district_name: "Salpazarı",
    status: 1,
  },
  {
    dist_id: 816,
    city_id: 61,
    district_name: "Tonya",
    status: 1,
  },
  {
    dist_id: 817,
    city_id: 61,
    district_name: "Vakfıkebir",
    status: 1,
  },
  {
    dist_id: 818,
    city_id: 61,
    district_name: "Yomra",
    status: 1,
  },
  {
    dist_id: 819,
    city_id: 62,
    district_name: "Tunceli",
    status: 1,
  },
  {
    dist_id: 820,
    city_id: 62,
    district_name: "Çemisgezek",
    status: 1,
  },
  {
    dist_id: 821,
    city_id: 62,
    district_name: "Hozat",
    status: 1,
  },
  {
    dist_id: 822,
    city_id: 62,
    district_name: "Mazgirt",
    status: 1,
  },
  {
    dist_id: 823,
    city_id: 62,
    district_name: "Nazımiye",
    status: 1,
  },
  {
    dist_id: 824,
    city_id: 62,
    district_name: "Ovacık",
    status: 1,
  },
  {
    dist_id: 825,
    city_id: 62,
    district_name: "Pertek",
    status: 1,
  },
  {
    dist_id: 826,
    city_id: 62,
    district_name: "Pülümür",
    status: 1,
  },
  {
    dist_id: 827,
    city_id: 63,
    district_name: "Sanlıurfa",
    status: 1,
  },
  {
    dist_id: 828,
    city_id: 63,
    district_name: "Akçakale",
    status: 1,
  },
  {
    dist_id: 829,
    city_id: 63,
    district_name: "Birecik",
    status: 1,
  },
  {
    dist_id: 830,
    city_id: 63,
    district_name: "Bozova",
    status: 1,
  },
  {
    dist_id: 831,
    city_id: 63,
    district_name: "Ceylanpınar",
    status: 1,
  },
  {
    dist_id: 832,
    city_id: 63,
    district_name: "Halfeti",
    status: 1,
  },
  {
    dist_id: 833,
    city_id: 63,
    district_name: "Harran",
    status: 1,
  },
  {
    dist_id: 834,
    city_id: 63,
    district_name: "Hilvan",
    status: 1,
  },
  {
    dist_id: 835,
    city_id: 63,
    district_name: "Siverek",
    status: 1,
  },
  {
    dist_id: 836,
    city_id: 63,
    district_name: "Suruç",
    status: 1,
  },
  {
    dist_id: 837,
    city_id: 63,
    district_name: "Viransehir",
    status: 1,
  },
  {
    dist_id: 838,
    city_id: 64,
    district_name: "Usak",
    status: 1,
  },
  {
    dist_id: 839,
    city_id: 64,
    district_name: "Banaz",
    status: 1,
  },
  {
    dist_id: 840,
    city_id: 64,
    district_name: "Esme",
    status: 1,
  },
  {
    dist_id: 841,
    city_id: 64,
    district_name: "Karahallı",
    status: 1,
  },
  {
    dist_id: 842,
    city_id: 64,
    district_name: "Sivaslı",
    status: 1,
  },
  {
    dist_id: 843,
    city_id: 64,
    district_name: "Ulubey",
    status: 1,
  },
  {
    dist_id: 844,
    city_id: 65,
    district_name: "Van",
    status: 1,
  },
  {
    dist_id: 845,
    city_id: 65,
    district_name: "Bahçesaray",
    status: 1,
  },
  {
    dist_id: 846,
    city_id: 65,
    district_name: "Baskale",
    status: 1,
  },
  {
    dist_id: 847,
    city_id: 65,
    district_name: "Çaldıran",
    status: 1,
  },
  {
    dist_id: 848,
    city_id: 65,
    district_name: "Çatak",
    status: 1,
  },
  {
    dist_id: 849,
    city_id: 65,
    district_name: "Edremit(Gümüsdere)",
    status: 1,
  },
  {
    dist_id: 850,
    city_id: 65,
    district_name: "Ercis",
    status: 1,
  },
  {
    dist_id: 851,
    city_id: 65,
    district_name: "Gevas",
    status: 1,
  },
  {
    dist_id: 852,
    city_id: 65,
    district_name: "Gürpınar",
    status: 1,
  },
  {
    dist_id: 853,
    city_id: 65,
    district_name: "Muradiye",
    status: 1,
  },
  {
    dist_id: 854,
    city_id: 65,
    district_name: "Özalp",
    status: 1,
  },
  {
    dist_id: 855,
    city_id: 65,
    district_name: "Saray",
    status: 1,
  },
  {
    dist_id: 856,
    city_id: 66,
    district_name: "Yozgat",
    status: 1,
  },
  {
    dist_id: 857,
    city_id: 66,
    district_name: "Akdagmadeni",
    status: 1,
  },
  {
    dist_id: 858,
    city_id: 66,
    district_name: "Aydıncık",
    status: 1,
  },
  {
    dist_id: 859,
    city_id: 66,
    district_name: "Bogazlıyan",
    status: 1,
  },
  {
    dist_id: 860,
    city_id: 66,
    district_name: "Çandır",
    status: 1,
  },
  {
    dist_id: 861,
    city_id: 66,
    district_name: "Çayıralan",
    status: 1,
  },
  {
    dist_id: 862,
    city_id: 66,
    district_name: "Çekerek",
    status: 1,
  },
  {
    dist_id: 863,
    city_id: 66,
    district_name: "Kadısehri",
    status: 1,
  },
  {
    dist_id: 864,
    city_id: 66,
    district_name: "Saraykent",
    status: 1,
  },
  {
    dist_id: 865,
    city_id: 66,
    district_name: "Sarıkaya",
    status: 1,
  },
  {
    dist_id: 866,
    city_id: 66,
    district_name: "Sorgun",
    status: 1,
  },
  {
    dist_id: 867,
    city_id: 66,
    district_name: "Sefaatli",
    status: 1,
  },
  {
    dist_id: 868,
    city_id: 66,
    district_name: "Yenifakılı",
    status: 1,
  },
  {
    dist_id: 869,
    city_id: 66,
    district_name: "Yerköy",
    status: 1,
  },
  {
    dist_id: 870,
    city_id: 67,
    district_name: "Zonguldak",
    status: 1,
  },
  {
    dist_id: 871,
    city_id: 67,
    district_name: "Alaplı",
    status: 1,
  },
  {
    dist_id: 872,
    city_id: 67,
    district_name: "Çaycuma",
    status: 1,
  },
  {
    dist_id: 873,
    city_id: 67,
    district_name: "Devrek",
    status: 1,
  },
  {
    dist_id: 874,
    city_id: 67,
    district_name: "Karadeniz Eregli",
    status: 1,
  },
  {
    dist_id: 875,
    city_id: 67,
    district_name: "Gökçebey",
    status: 1,
  },
  {
    dist_id: 876,
    city_id: 68,
    district_name: "Aksaray",
    status: 1,
  },
  {
    dist_id: 877,
    city_id: 68,
    district_name: "Agaçören",
    status: 1,
  },
  {
    dist_id: 878,
    city_id: 68,
    district_name: "Eskil",
    status: 1,
  },
  {
    dist_id: 879,
    city_id: 68,
    district_name: "Gülagaç(Agaçlı)",
    status: 1,
  },
  {
    dist_id: 880,
    city_id: 68,
    district_name: "Güzelyurt",
    status: 1,
  },
  {
    dist_id: 881,
    city_id: 68,
    district_name: "Ortaköy",
    status: 1,
  },
  {
    dist_id: 882,
    city_id: 68,
    district_name: "Sarıyahsi",
    status: 1,
  },
  {
    dist_id: 883,
    city_id: 69,
    district_name: "Bayburt",
    status: 1,
  },
  {
    dist_id: 884,
    city_id: 69,
    district_name: "Aydıntepe",
    status: 1,
  },
  {
    dist_id: 885,
    city_id: 69,
    district_name: "Demirözü",
    status: 1,
  },
  {
    dist_id: 886,
    city_id: 70,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 887,
    city_id: 70,
    district_name: "Ayrancı",
    status: 1,
  },
  {
    dist_id: 888,
    city_id: 70,
    district_name: "Basyayla",
    status: 1,
  },
  {
    dist_id: 889,
    city_id: 70,
    district_name: "Ermenek",
    status: 1,
  },
  {
    dist_id: 890,
    city_id: 70,
    district_name: "Kazımkarabekir",
    status: 1,
  },
  {
    dist_id: 891,
    city_id: 70,
    district_name: "Sarıveliler",
    status: 1,
  },
  {
    dist_id: 892,
    city_id: 71,
    district_name: "Kırıkkale",
    status: 1,
  },
  {
    dist_id: 893,
    city_id: 71,
    district_name: "Bahsili",
    status: 1,
  },
  {
    dist_id: 894,
    city_id: 71,
    district_name: "Balıseyh",
    status: 1,
  },
  {
    dist_id: 895,
    city_id: 71,
    district_name: "Çelebi",
    status: 1,
  },
  {
    dist_id: 896,
    city_id: 71,
    district_name: "Delice",
    status: 1,
  },
  {
    dist_id: 897,
    city_id: 71,
    district_name: "Karakeçili",
    status: 1,
  },
  {
    dist_id: 898,
    city_id: 71,
    district_name: "Keskin",
    status: 1,
  },
  {
    dist_id: 899,
    city_id: 71,
    district_name: "Sulakyurt",
    status: 1,
  },
  {
    dist_id: 900,
    city_id: 71,
    district_name: "Yahsihan",
    status: 1,
  },
  {
    dist_id: 901,
    city_id: 72,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 902,
    city_id: 72,
    district_name: "Besiri",
    status: 1,
  },
  {
    dist_id: 903,
    city_id: 72,
    district_name: "Gercüs",
    status: 1,
  },
  {
    dist_id: 904,
    city_id: 72,
    district_name: "Hasankeyf",
    status: 1,
  },
  {
    dist_id: 905,
    city_id: 72,
    district_name: "Kozluk",
    status: 1,
  },
  {
    dist_id: 906,
    city_id: 72,
    district_name: "Sason",
    status: 1,
  },
  {
    dist_id: 907,
    city_id: 73,
    district_name: "Sırnak",
    status: 1,
  },
  {
    dist_id: 908,
    city_id: 73,
    district_name: "Beytüssebap",
    status: 1,
  },
  {
    dist_id: 909,
    city_id: 73,
    district_name: "Cizre",
    status: 1,
  },
  {
    dist_id: 910,
    city_id: 73,
    district_name: "Güçlükonak",
    status: 1,
  },
  {
    dist_id: 911,
    city_id: 73,
    district_name: "Idil",
    status: 1,
  },
  {
    dist_id: 912,
    city_id: 73,
    district_name: "Silopi",
    status: 1,
  },
  {
    dist_id: 913,
    city_id: 73,
    district_name: "Uludere",
    status: 1,
  },
  {
    dist_id: 914,
    city_id: 74,
    district_name: "Bartın",
    status: 1,
  },
  {
    dist_id: 915,
    city_id: 74,
    district_name: "Amasra",
    status: 1,
  },
  {
    dist_id: 916,
    city_id: 74,
    district_name: "Kurucasile",
    status: 1,
  },
  {
    dist_id: 917,
    city_id: 74,
    district_name: "Ulus",
    status: 1,
  },
  {
    dist_id: 918,
    city_id: 75,
    district_name: "Ardahan",
    status: 1,
  },
  {
    dist_id: 919,
    city_id: 75,
    district_name: "Çıldır",
    status: 1,
  },
  {
    dist_id: 920,
    city_id: 75,
    district_name: "Damal",
    status: 1,
  },
  {
    dist_id: 921,
    city_id: 75,
    district_name: "Göle",
    status: 1,
  },
  {
    dist_id: 922,
    city_id: 75,
    district_name: "Hanak",
    status: 1,
  },
  {
    dist_id: 923,
    city_id: 75,
    district_name: "Posof",
    status: 1,
  },
  {
    dist_id: 924,
    city_id: 76,
    district_name: "Igdır",
    status: 1,
  },
  {
    dist_id: 925,
    city_id: 76,
    district_name: "Aralık",
    status: 1,
  },
  {
    dist_id: 926,
    city_id: 76,
    district_name: "Karakoyunlu",
    status: 1,
  },
  {
    dist_id: 927,
    city_id: 76,
    district_name: "Tuzluca",
    status: 1,
  },
  {
    dist_id: 928,
    city_id: 77,
    district_name: "Yalova",
    status: 1,
  },
  {
    dist_id: 929,
    city_id: 77,
    district_name: "Altınova",
    status: 1,
  },
  {
    dist_id: 930,
    city_id: 77,
    district_name: "Armutlu",
    status: 1,
  },
  {
    dist_id: 931,
    city_id: 77,
    district_name: "Çiftlikköy",
    status: 1,
  },
  {
    dist_id: 932,
    city_id: 77,
    district_name: "Çınarcık",
    status: 1,
  },
  {
    dist_id: 933,
    city_id: 77,
    district_name: "Termal",
    status: 1,
  },
  {
    dist_id: 934,
    city_id: 78,
    district_name: "Karabük",
    status: 1,
  },
  {
    dist_id: 935,
    city_id: 78,
    district_name: "Eflani",
    status: 1,
  },
  {
    dist_id: 936,
    city_id: 78,
    district_name: "Eskipazar",
    status: 1,
  },
  {
    dist_id: 937,
    city_id: 78,
    district_name: "Ovacık",
    status: 1,
  },
  {
    dist_id: 938,
    city_id: 78,
    district_name: "Safranbolu",
    status: 1,
  },
  {
    dist_id: 939,
    city_id: 78,
    district_name: "Yenice",
    status: 1,
  },
  {
    dist_id: 940,
    city_id: 79,
    district_name: "Kilis",
    status: 1,
  },
  {
    dist_id: 941,
    city_id: 79,
    district_name: "Elbeyli",
    status: 1,
  },
  {
    dist_id: 942,
    city_id: 79,
    district_name: "Musabeyli",
    status: 1,
  },
  {
    dist_id: 943,
    city_id: 79,
    district_name: "Polateli",
    status: 1,
  },
  {
    dist_id: 944,
    city_id: 80,
    district_name: "Osmaniye",
    status: 1,
  },
  {
    dist_id: 945,
    city_id: 80,
    district_name: "Bahçe",
    status: 1,
  },
  {
    dist_id: 946,
    city_id: 80,
    district_name: "Düziçi",
    status: 1,
  },
  {
    dist_id: 947,
    city_id: 80,
    district_name: "Hasanbeyli",
    status: 1,
  },
  {
    dist_id: 948,
    city_id: 80,
    district_name: "Kadirli",
    status: 1,
  },
  {
    dist_id: 949,
    city_id: 80,
    district_name: "Sumbas",
    status: 1,
  },
  {
    dist_id: 950,
    city_id: 80,
    district_name: "Toprakkale",
    status: 1,
  },
  {
    dist_id: 951,
    city_id: 81,
    district_name: "Düzce",
    status: 1,
  },
  {
    dist_id: 952,
    city_id: 81,
    district_name: "Akçakoca",
    status: 1,
  },
  {
    dist_id: 953,
    city_id: 81,
    district_name: "Cumayeri",
    status: 1,
  },
  {
    dist_id: 954,
    city_id: 81,
    district_name: "Çilimli",
    status: 1,
  },
  {
    dist_id: 955,
    city_id: 81,
    district_name: "Gölyaka",
    status: 1,
  },
  {
    dist_id: 956,
    city_id: 81,
    district_name: "Gümüsova",
    status: 1,
  },
  {
    dist_id: 957,
    city_id: 81,
    district_name: "Kaynaslı",
    status: 1,
  },
  {
    dist_id: 958,
    city_id: 81,
    district_name: "Yıgılca",
    status: 1,
  },
  {
    dist_id: 962,
    city_id: 20,
    district_name: "Pamukkale",
    status: 1,
  },
  {
    dist_id: 963,
    city_id: 7,
    district_name: "Olympos",
    status: 1,
  },
  {
    dist_id: 964,
    city_id: 7,
    district_name: "Çıralı",
    status: 1,
  },
  {
    dist_id: 965,
    city_id: 7,
    district_name: "Kaleiçi",
    status: 1,
  },
  {
    dist_id: 967,
    city_id: 33,
    district_name: "Kızkalesi",
    status: 1,
  },
  {
    dist_id: 968,
    city_id: 20,
    district_name: "Karahayit",
    status: 1,
  },
  {
    dist_id: 969,
    city_id: 6,
    district_name: "Sıhhiye",
    status: 1,
  },
  {
    dist_id: 970,
    city_id: 27,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 971,
    city_id: 35,
    district_name: "Alsancak",
    status: 1,
  },
  {
    dist_id: 972,
    city_id: 21,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 973,
    city_id: 41,
    district_name: "Körfez",
    status: 1,
  },
  {
    dist_id: 974,
    city_id: 45,
    district_name: "Yunusemre",
    status: 1,
  },
  {
    dist_id: 975,
    city_id: 10,
    district_name: "Altıeylül",
    status: 1,
  },
  {
    dist_id: 976,
    city_id: 46,
    district_name: "Onikisubat",
    status: 1,
  },
  {
    dist_id: 977,
    city_id: 20,
    district_name: "Merkezefendi",
    status: 1,
  },
  {
    dist_id: 978,
    city_id: 63,
    district_name: "Haliliye",
    status: 1,
  },
  {
    dist_id: 979,
    city_id: 52,
    district_name: "Altınordu",
    status: 1,
  },
  {
    dist_id: 980,
    city_id: 59,
    district_name: "Kapaklı",
    status: 1,
  },
  {
    dist_id: 981,
    city_id: 61,
    district_name: "Ortahisar",
    status: 1,
  },
  {
    dist_id: 982,
    city_id: 6,
    district_name: "Kahramankazan",
    status: 1,
  },
  {
    dist_id: 983,
    city_id: 31,
    district_name: "Arsuz",
    status: 1,
  },
  {
    dist_id: 984,
    city_id: 67,
    district_name: "Kozlu",
    status: 1,
  },
  {
    dist_id: 985,
    city_id: 10,
    district_name: "Karesi",
    status: 1,
  },
  {
    dist_id: 986,
    city_id: 46,
    district_name: "Dulkadiroglu",
    status: 1,
  },
  {
    dist_id: 987,
    city_id: 45,
    district_name: "Sehzadeler",
    status: 1,
  },
  {
    dist_id: 988,
    city_id: 48,
    district_name: "Mentese",
    status: 1,
  },
  {
    dist_id: 989,
    city_id: 47,
    district_name: "Artuklu",
    status: 1,
  },
  {
    dist_id: 990,
    city_id: 63,
    district_name: "Karaköprü",
    status: 1,
  },
  {
    dist_id: 991,
    city_id: 63,
    district_name: "Eyyübiye",
    status: 1,
  },
  {
    dist_id: 992,
    city_id: 9,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 993,
    city_id: 41,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 994,
    city_id: 2,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 995,
    city_id: 3,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 996,
    city_id: 4,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 997,
    city_id: 4,
    district_name: "Dogubayazıt",
    status: 1,
  },
  {
    dist_id: 998,
    city_id: 5,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 999,
    city_id: 8,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1000,
    city_id: 8,
    district_name: "Kemalpasa",
    status: 1,
  },
  {
    dist_id: 1001,
    city_id: 11,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1002,
    city_id: 12,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1003,
    city_id: 12,
    district_name: "Kigı",
    status: 1,
  },
  {
    dist_id: 1004,
    city_id: 13,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1005,
    city_id: 14,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1006,
    city_id: 15,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1007,
    city_id: 17,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1008,
    city_id: 18,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1009,
    city_id: 19,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1010,
    city_id: 22,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1011,
    city_id: 23,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1012,
    city_id: 24,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1013,
    city_id: 28,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1014,
    city_id: 29,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1015,
    city_id: 30,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1016,
    city_id: 30,
    district_name: "Derecik",
    status: 1,
  },
  {
    dist_id: 1017,
    city_id: 31,
    district_name: "Defne",
    status: 1,
  },
  {
    dist_id: 1018,
    city_id: 31,
    district_name: "Payas",
    status: 1,
  },
  {
    dist_id: 1019,
    city_id: 32,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1020,
    city_id: 34,
    district_name: "Eyüpsultan",
    status: 1,
  },
  {
    dist_id: 1021,
    city_id: 36,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1022,
    city_id: 37,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1023,
    city_id: 39,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1024,
    city_id: 40,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1025,
    city_id: 43,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1026,
    city_id: 44,
    district_name: "Arapgir",
    status: 1,
  },
  {
    dist_id: 1027,
    city_id: 44,
    district_name: "Pütürge",
    status: 1,
  },
  {
    dist_id: 1028,
    city_id: 48,
    district_name: "Seydikemer",
    status: 1,
  },
  {
    dist_id: 1029,
    city_id: 49,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1030,
    city_id: 50,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1031,
    city_id: 50,
    district_name: "Ürgüp",
    status: 1,
  },
  {
    dist_id: 1032,
    city_id: 51,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1033,
    city_id: 53,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1034,
    city_id: 55,
    district_name: "19 Mayıs",
    status: 1,
  },
  {
    dist_id: 1035,
    city_id: 56,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1036,
    city_id: 56,
    district_name: "Tillo",
    status: 1,
  },
  {
    dist_id: 1037,
    city_id: 57,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1038,
    city_id: 58,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1039,
    city_id: 59,
    district_name: "Ergene",
    status: 1,
  },
  {
    dist_id: 1040,
    city_id: 59,
    district_name: "Süleymanpasa",
    status: 1,
  },
  {
    dist_id: 1041,
    city_id: 60,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1042,
    city_id: 62,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1043,
    city_id: 64,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1044,
    city_id: 65,
    district_name: "Ipekyolu",
    status: 1,
  },
  {
    dist_id: 1045,
    city_id: 65,
    district_name: "Tusba",
    status: 1,
  },
  {
    dist_id: 1046,
    city_id: 66,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1047,
    city_id: 67,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1048,
    city_id: 67,
    district_name: "Kilimli",
    status: 1,
  },
  {
    dist_id: 1049,
    city_id: 68,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1050,
    city_id: 68,
    district_name: "Sultanhanı",
    status: 1,
  },
  {
    dist_id: 1051,
    city_id: 69,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1052,
    city_id: 71,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1053,
    city_id: 71,
    district_name: "Bahsılı",
    status: 1,
  },
  {
    dist_id: 1054,
    city_id: 73,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1055,
    city_id: 74,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1056,
    city_id: 75,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1057,
    city_id: 76,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1058,
    city_id: 77,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1059,
    city_id: 78,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1060,
    city_id: 79,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1061,
    city_id: 80,
    district_name: "Merkez",
    status: 1,
  },
  {
    dist_id: 1062,
    city_id: 81,
    district_name: "Merkez",
    status: 1,
  },
];
