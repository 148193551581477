import React from "react";
import "./BrandGrid.css";
import { Link } from "react-scroll";

const LogoGallery = () => {
  return (
    <div className="brand-grid z-10001">
      <div className="brand-card">
        <Link to="Apple" smooth={true} duration={1000}>
          <img src="apple-logo.png" alt="Apple Logo" className="brand-logos" />
        </Link>
      </div>
      <div className="brand-card">
        <Link to="samsung" smooth={true} duration={1000}>
          <img
            src="samsung-logo.png"
            alt="Samsung Logo"
            className="brand-logos"
          />
        </Link>
      </div>
      <div className="brand-card">
        <a href="logo">
          <img
            src="xiaomi-logo.png"
            alt="Xiaomi Logo"
            className="brand-logos"
          />
        </a>
      </div>
      <div className="brand-card">
        <a href="logo">
          <img
            src="huawei-logo.png"
            alt="Huawei Logo"
            className="brand-logos"
          />
        </a>
      </div>
      <div className="brand-card">
        <a href="logo">
          <img src="oppo-logo.png" alt="Oppo Logo" className="brand-logos" />
        </a>
      </div>
      <div className="brand-card">
        <a href="logo">
          <img
            src="realme-logo.png"
            alt="Realme Logo"
            className="brand-logos"
          />
        </a>
      </div>
    </div>
  );
};

export default LogoGallery;
