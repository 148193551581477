import React, { useState } from "react";
import { FaFileExcel } from "react-icons/fa"; // Excel ikonunu içeri aktarıyoruz
import { RiFileExcel2Fill } from "react-icons/ri";
import PriceListModal from "./PriceListModal";

const ExcelButton = () => {
  const openPriceModal = () => setShowPriceModal(true);
  const closePriceModal = () => setShowPriceModal(false);

  const [showPriceModal, setShowPriceModal] = useState(false);

  return (
    <div>
      <div className="excel-wrapper" onClick={openPriceModal}>
        <RiFileExcel2Fill className="svg-excel" />
      </div>
      <PriceListModal show={showPriceModal} onClose={closePriceModal} />
    </div>
  );
};

export default ExcelButton;
