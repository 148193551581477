import React, { useState } from "react";
import appleLogo from "../assets/images/apple-logo.png";
import realmeLogo from "../assets/images/realme-logo.png";
import samsungLogo from "../assets/images/samsung-logo.png";
import pocoLogo from "../assets/images/poco-logo.png";
import xiaomiLogo from "../assets/images/xiaomi-logo.png";
import huaweiLogo from "../assets/images/huawei-logo.png";
import oppoLogo from "../assets/images/oppo-logo.png";
import vivoLogo from "../assets/images/vivo-logo.png";
import tecnoLogo from "../assets/images/tecno-logo.png";
import reederLogo from "../assets/images/reeder-logo.png";
import alcatelLogo from "../assets/images/alcatel-logo.png";
import casperLogo from "../assets/images/casper-logo.png";
import omixLogo from "../assets/images/omix-logo.png";
import { Modal } from "react-bootstrap";
import { FaYoutube, FaChevronDown, FaChevronUp } from "react-icons/fa";

const images = {
  Alcatel: alcatelLogo,
  Apple: appleLogo,
  Casper: casperLogo,
  Huawei: huaweiLogo,
  Oppo: oppoLogo,
  Poco: pocoLogo,
  RealMe: realmeLogo,
  Reeder: reederLogo,
  Samsung: samsungLogo,
  Tecno: tecnoLogo,
  Vivo: vivoLogo,
  Xiaomi: xiaomiLogo,
  Omix: omixLogo,
};

const ModelItem = ({ form }) => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleAccordion = () => setIsExpanded(!isExpanded);

  const shortenTitle = (title) => {
    return title.replace(/Cep Telefonu/g, "").trim();
  };

  <Modal.Title>{shortenTitle(form.variant_title)}</Modal.Title>;

  const processAndSplitOutletAnswers = (answers) => {
    const mapping = {
      "Müdahale Var": null,
      "Çizgi/Leke": "Çizgi/Piksel Problemi",
      "Cam D Grade": "Cam Çizik",
      "Bazı fonksiyonlar çalışmıyor": null,
      "Bayi Özel": "Kasa Kozmetiği",
      "ID Pasif": "ID Pasif",
      "2 No Leke": "Kamera Lekeli",
      "3 No Leke": "Kamera Lekeli",
      "4 No Leke": "Kamera Lekeli",
      "Batarya bilinmiyor": "Batarya Bilinmiyor",
      "Ekran bilinmiyor": "Ekran Bilinmiyor",
      "Kamera bilinmiyor": "Kamera Bilinmiyor",
      "2 No Gölge": "2 No Gölge",
      Çatlak: "ID Okuyucu Çatlak",
      "3 No Gölge": "3 No Gölge",
      "4 No Gölge": "4 No Gölge",
      "BL Lekeli": "BL Lekeli",
      "TT Pasif": "TrueTone Pasif",
    };

    if (!answers) return ["Hata Yok"]; // Eğer cevap yoksa "Hata Yok" döndür

    // Gelen cevapları ayrıştırma ve eşleştirme
    const filteredAnswers = answers
      .split(";") // Gelen cevapları ayır
      .map((answer) => answer.trim()) // Her bir cevabı temizle
      .map((answer) => mapping[answer] || answer) // Mapping'e göre dönüştür
      .filter((answer) => answer); // Null olanları çıkar

    if (filteredAnswers.length === 0) return ["Hata Yok"]; // Filtrelenmiş liste boşsa

    return filteredAnswers.slice(0, 2); // İlk iki cevabı döndür
  };

  const [firstAnswer, secondAnswer] = processAndSplitOutletAnswers(
    form.outlet_answers
  );
  const removeFirstMemoryPart = (modelName) => {
    if (!modelName) return ""; // Eğer modelName boşsa, boş string döndür
    return modelName.replace(/\d+\/\s*/, "").trim(); // "4/" gibi ifadeyi kaldır
  };
  const normalizeClassName = (text) => {
    const turkishChars = {
      Ç: "c",
      Ğ: "g",
      İ: "i",
      Ö: "o",
      Ş: "s",
      Ü: "u",
      ç: "c",
      ğ: "g",
      ı: "i",
      ö: "o",
      ş: "s",
      ü: "u",
    };
    return text
      .replace(/[ÇĞİÖŞÜçğıöşü]/g, (char) => turkishChars[char] || char)
      .toLowerCase();
  };

  const formatGradeTitle = (gradeTitle) => {
    if (!gradeTitle) return ""; // Eğer veri yoksa boş döndür

    const gradeMapping = {
      "Delta Platinum": "Mükemmel",
      "Beta Gold": "Çok İyi",
      "Alfa Silver": "İyi",
      "Bayi Özel": "Bayi Özel",
    };

    return gradeMapping[gradeTitle] || gradeTitle;
  };

  const colorTranslations = {
    Gold: "Altın",
    White: "Beyaz",
    Silver: "Gümüş",
    Red: "Kırmızı",
    Blue: "Mavi",
    "Space Grey": "Uzay Grisi",
    Green: "Yeşil",
    Orange: "Turuncu",
    Purple: "Mor",
    Black: "Siyah",
    "Rose Gold": "Rose Gold",
    Pink: "Pembe",
    Copper: "Bakır",
    Grey: "Gri",
  };

  function translateColor(color) {
    return colorTranslations[color] || color;
  }

  const cleanModelName = (modelName) => {
    if (!modelName) return ""; // Eğer modelName boşsa, boş string döndür

    const brands = [
      "Apple",
      "Samsung",
      "Huawei",
      "Xiaomi",
      "RealMe",
      "Oppo",
      "Vivo",
      "Tecno",
      "Poco",
      "Casper",
      "Alcatel",
    ];

    const brandRegex = new RegExp(`^(${brands.join("|")})\\s`, "i");

    const withoutBrand = modelName.replace(brandRegex, "").trim();

    const memoryRegex = /\d+\/\s*/;
    const cleanedName = withoutBrand.replace(memoryRegex, "").trim();

    return cleanedName;
  };

  return (
    <div className="item-wrappers p-0">
      <table className="details-table tablecolor hidden lg:table">
        <tbody>
          <tr>
            <td>{form.form_id}</td>

            <td>{removeFirstMemoryPart(form.model_name)}</td>
            <td>
              <div
                className={`grade-cells ${
                  form.grade_title
                    ? normalizeClassName(
                        formatGradeTitle(form.grade_title).replace(" ", "-")
                      )
                    : ""
                }`}
              >
                <th className="flex justify-center items-center ">
                  {formatGradeTitle(form.grade_title)}
                </th>
              </div>
            </td>
            {/* <td>{formatGradeTitle(form.grade_title)}</td> */}
            <td>{translateColor(form.color)}</td>

            <td>
              {firstAnswer || secondAnswer ? (
                <div className="gradient-cell flex space-x-4 justify-center items-center pl-2">
                  {firstAnswer && <th>{firstAnswer}</th>}
                  {secondAnswer && (
                    <th className="border-l border-gray-700 pl-4">
                      {secondAnswer}
                    </th>
                  )}
                </div>
              ) : (
                <>
                  <td className="gradient-cell">Hata Yok</td>
                </>
              )}
            </td>

            <td>
              <th className=" flex justify-center items-center">
                {form.price.split(",")[0]}₺
              </th>
            </td>

            <td>
              <button
                onClick={form.video_url ? handleShow : null}
                disabled={!form.video_url}
                className={`video-button ${!form.video_url ? "disabled" : ""}`}
              >
                <span className="video-icon text-red-500">
                  <FaYoutube />
                </span>
                <span className="video-text">Video</span>
              </button>
            </td>
            <td>
              <div className="flex justify-center items-center">
                <a
                  rel="noopener noreferrer"
                  href={
                    "https://isortagim.deltaservis.com.tr/outlet-details/" +
                    form.form_id
                  }
                  target="_blank"
                  className="purchase-button-mobile flex items-center gap-2 px-4 py-2 rounded-md text-white font-semibold "
                >
                  <img
                    src="/purchase.png"
                    alt="Satın Al"
                    className="w-6 h-6 "
                  />
                  <span>Satın Al</span>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="block lg:hidden p-2" onClick={toggleAccordion}>
        <div className="flex items-center justify-between w-full space-x-2">
          <span className="flex-grow text-left">
            {cleanModelName(form.model_name)}
          </span>

          <span className="flex items-center justify-center">
            {form.video_url && (
              <button
                onClick={form.video_url ? handleShow : null}
                disabled={!form.video_url}
                className={`video-button-outlet flex items-center px-2 py-2 text-white border-none rounded-md hover:scale-105 transition-all duration-500 ${
                  !form.video_url ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <span className="inline-block mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="w-4 h-4"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </span>
                <span className="text-sm font-medium">Video</span>
              </button>
            )}
          </span>

          {/* Accordion Button */}
          <div
            className="cursor-pointer flex-shrink-0"
            onClick={toggleAccordion}
          >
            <div>
              {isExpanded ? (
                <FaChevronUp size={18} className="text-gray-400" />
              ) : (
                <FaChevronDown size={18} className="text-gray-400" />
              )}
            </div>
          </div>
        </div>

        {/* Accordion içeriği */}
        <div
          className={`transition-[max-height,opacity,transform] duration-500 ease-in-out ${
            isExpanded
              ? "max-h-[500px] opacity-100 translate-y-0 overflow-visible"
              : "max-h-0 opacity-0 -translate-y-2 overflow-hidden"
          }`}
        >
          <div className="mt-2 space-y-2 flex pl-1 ">
            {/* Özellikler */}

            <div className="text-gray-100 ">
              <div className="space-x-3">
                Ürün ID: <span className="font-bold">{form.form_id}</span>
              </div>
              <div className="space-x-3">
                <span className="text-gray-100 font-semibold">Renk:</span>{" "}
                {translateColor(form.color)}{" "}
                <span className="text-gray-100 font-semibold">
                  {" "}
                  Kalite: {formatGradeTitle(form.grade_title)}
                </span>
              </div>

              <div>
                <span className="text-gray-100 font-semibold">
                  Outlet Nedeni:{" "}
                </span>
                {form.outlet_answers || "Veri yok"}
              </div>
              <div className="space-x-3">
                <span className="font-semibold">Fiyat:</span>
                <span className="line-through font-extrabold decoration-red-600">
                  {" "}
                  {form.retail_price.split(",")[0]}₺{" "}
                </span>
                <span className="font-extrabold">
                  {" "}
                  {form.price.split(",")[0]}₺
                </span>
              </div>
              <div className="space-x-3 flex items-center pt-2">
                <a
                  rel="noopener noreferrer"
                  href={
                    "https://isortagim.deltaservis.com.tr/outlet-details/" +
                    form.form_id
                  }
                  target="_blank"
                  className="purchase-button-mobile flex items-center gap-2 px-4 py-2 rounded-md text-white font-semibold "
                >
                  <img
                    src="/purchase.png"
                    alt="Satın Al"
                    className="w-6 h-6 "
                  />
                  <span>Satın Al</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} centered size="lg">
        {/* Header kısmı */}
        <Modal.Header
          closeButton
          className=" bg-black flex flex-col items-center justify-center text-center border-none "
        >
          <Modal.Title className="Modal_Title w-full">
            <div className="space-y-2 w-full text-center">
              {/* Model ve Durum Bilgisi */}
              <div className="border rounded-lg p-1 text-white">
                {removeFirstMemoryPart(form.model_name)} -{" "}
                {formatGradeTitle(form.grade_title)}
              </div>
              {/* Form ID ve Fiyat Bilgisi */}
              <div className="border rounded-lg p-1 text-white">
                Form: {form.form_id} | Fiyat: {form.price.split(",")[0]}₺
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>

        {/* Body kısmı */}
        <Modal.Body className="p-4 flex justify-center items-center  bg-gradient-to-t from-slate-950 via-slate-9500 to-black">
          <div className="w-full max-w-2xl rounded-lg overflow-hidden shadow-lg">
            {/* Video Çerçevesi */}
            <iframe
              src={form.video_url}
              width="100%"
              height="400"
              title="Video"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full rounded-xl"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const CategoryItem = ({ brand, brandTitle }) => {
  const [visibleCount, setVisibleCount] = useState(15);

  const imageUrl = images[brand.brand_title] || "Logo";

  const forms = brand.forms || [];
  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 15);
  };
  return (
    <div className="main-card card custom-bg" id={brandTitle}>
      <div>
        <div className="card-header">
          <div className="card-title m-0">
            {imageUrl ? (
              <img
                className="brand-logo w-36"
                alt={brandTitle}
                src={imageUrl}
              />
            ) : (
              <h4 className="m-0 d-inline">{brandTitle}</h4>
            )}
          </div>
        </div>
      </div>

      <div className="card-body p-0">
        {forms.slice(0, visibleCount).map((form) => (
          <ModelItem key={form.form_id} form={form} />
        ))}

        {visibleCount < forms.length && (
          <button
            onClick={loadMore}
            className="mt-4 bg-gradient-to-r from-cyan-400 via-green-500 to-yellow-500 text-white font-semibold p-4 rounded-lg shadow-lg hover:from-yellow-500 hover:via-green-500 hover:to-cyan-400 px-4 py-3 w-full text-lg flex items-center justify-center space-x-3"
          >
            <FaChevronDown className="text-white" />
            <span className="text-2xl font-extrabold">Daha Fazla Göster</span>
            <FaChevronDown className="text-white" />
          </button>
        )}
      </div>
    </div>
  );
};

const ProductList = ({ data }) => {
  return (
    <div>
      {Object.keys(data).map((brandTitle) => {
        const brand = data[brandTitle]; // 'data' nesnesinin içerisindeki markayı al
        return (
          <CategoryItem
            id={brandTitle.toLowerCase().replace(/\s+/g, "-")}
            key={brandTitle}
            brand={brand}
            brandTitle={brandTitle} // Marka başlığını alıyoruz
          />
        );
      })}
    </div>
  );
};

export default ProductList;
