import React from "react";
import { RiShoppingCart2Fill, RiShoppingCartFill } from "react-icons/ri";

const IsOrtagim = () => {
  return (
    <div>
      <a
        href="https://isortagim.deltaservis.com.tr/"
        target="_blank" rel="noreferrer"
        className="is-ortagim"
      >
        <RiShoppingCart2Fill className="svg-isortagim" />
      </a>
    </div>
  );
};

export default IsOrtagim;
