import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./WordRotate.css";

const WordRotate = ({ words, delay = 3500, animDelay = 400 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, delay);

    return () => clearInterval(interval); // Temizlik işlevi
  }, [words.length, delay]); // 'words.length' ve 'delay' bağımlılıkları

  return (
    <div className="word-rotate">
      <div className="word-rotate-items text-white  bg-gradient-to-r from-red-500 to-red-900 border-black">
        {words.map((word, index) => (
          <span
            key={index}
            className={index === currentIndex ? "visible" : "hidden"}
            style={{
              transition: `opacity ${animDelay}ms static`,
              opacity: index === currentIndex ? 1 : 0,
              position: index === currentIndex ? "relative" : "absolute",
            }}
          >
            {word}
          </span>
        ))}
      </div>
    </div>
  );
};

WordRotate.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired,
  delay: PropTypes.number,
  animDelay: PropTypes.number,
};

export default WordRotate;
